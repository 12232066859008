import { React } from 'react';
import { BsFillCheckCircleFill, BsFillPlusCircleFill, BsFillXCircleFill, BsTrashFill } from "react-icons/bs";
import { SimpleConfirmationComponent } from '../../components/Alerts';
import { Shared } from '../../components/Shared';
import { IsUserAdmin, capitalizeFirst, eventoObj, getAlarmEvaluationFull, getAlarmSelectionFull, getArrayFromString, getCurrentSession, getEmptyIcon, getFullGroupedMessage, getMetodosEnvio, getOperacionesFull, getPositiveAndNegativeFromArray, getRequiredIcon, getStringFromArray, joinAndPrefixArrays, secondsToHours } from '../../utils';

export class ConfigGroupedAlarms extends Shared {
    static displayName = ConfigGroupedAlarms.name;

    constructor(props) {
        super(props);
        this.state = {
            loggedUser: null,
            isAdminUser: false,
            alarms: null,
            confirmationTitle: '',
            confirmationMessage: '',
            confirmationMethod: '',

            operaciones: null,
            metodosEnvio: null,
            users: null,
            alarmSelection: null,
            alarmEvaluation: null,

            current_id: null,

            current_percentaje: 100,
            current_selection: 'Top',
            current_operation: 'Average',

            current_name: '',
            current_detail: '',
            current_event: '',
            current_value: '',
            current_operacion: 'Greater',
            current_locations_positives: '',
            current_locations_negatives: '',
            current_objects_positives: '',
            current_objects_negatives: '',
            current_list_metodos: [],
            current_list_users: [],

            current_activo: true,

            loading: true
        };
    }

    componentDidMount() {
        this.populateData();
    }

    clearSelection = async () => {
        this.setState({
            current_id: null,
            current_percentaje: 100,
            current_selection: 'Top',
            current_operation: 'Average',
            current_name: '',
            current_detail: '',
            current_event: '',
            current_value: '',
            current_operacion: 'Greater',
            current_locations_positives: '',
            current_locations_negatives: '',
            current_objects_positives: '',
            current_objects_negatives: '',
            current_list_metodos: [],
            current_list_users: [],
            current_activo: true,
        });
        this.clearConfirmation();
    }

    clearConfirmation = () => {
        this.setState({
            confirmationTitle: '',
            confirmationMessage: '',
            confirmationMethod: ''
        });
    }

    populateData = async () => {
        this.clearSelection();
        const loggedUser = getCurrentSession();
        this.setState({
            loggedUser: loggedUser,
            isAdminUser: IsUserAdmin(),
            alarmSelection: await getAlarmSelectionFull(),
            alarmEvaluation: await getAlarmEvaluationFull(),
            alarms: await this.getAlarmConfigs(loggedUser.empresa.id),
            operaciones: await getOperacionesFull(),
            metodosEnvio: await getMetodosEnvio(),
            users: await this.getUsers(loggedUser.empresa.id),
            loading: false
        });
    }

    getAlarmConfigs = async (empresa_id) => {
        const responseAlarmConfig = await fetch(`Main/GetAlarmGroupConfig/${empresa_id}`);
        if (responseAlarmConfig.ok) {
            const alarmConfig = await responseAlarmConfig.json();
            return alarmConfig;
        }
        return [];
    }

    getUsers = async (empresa_id) => {
        const response = await fetch(`Main/GetUsers/${empresa_id}`);
        if (response.ok) {
            const all_users = await response.json();
            return all_users;
        }
        return [];
    }

    rowClickHandler = async (selectedAlarmId) => {
        const selectedAlarmFilter = this.state.alarms.filter(item => item.id === selectedAlarmId);
        if (selectedAlarmFilter === null || selectedAlarmFilter === undefined || selectedAlarmFilter.length === 0) {
            this.setAlertMessage("Error al obtener datos de la configuracion seleccionada");
        }
        const selectedAlarmItem = selectedAlarmFilter[0];
        const users = this.state.users.map(function (user) {
            return {
                id: user.id,
                nombre: user.nombre,
                seleccionado: selectedAlarmItem.usuarios.map(usr => usr.id).includes(user.id)
            }
        }).sort((a, b) => {
            if (a.nombre > b.nombre) {
                return 1;
            }
            if (a.nombre < b.nombre) {
                return -1;
            }
            return 0;
        });

        const metodos = this.state.metodosEnvio.map(function (metodo) {
            return {
                metodo: metodo,
                seleccionado: selectedAlarmItem.metodosDeEnvio.includes(metodo)
            }
        });

        const curr_loc_pos_neg = getPositiveAndNegativeFromArray(selectedAlarmItem.lugares);
        const curr_obj_pos_neg = getPositiveAndNegativeFromArray(selectedAlarmItem.objetos);
        this.setState({
            current_percentaje: selectedAlarmItem.porcentajeEvaluado,
            current_selection: selectedAlarmItem.seccionEvaluada,
            current_operation: selectedAlarmItem.operacionEvaluada,
            current_name: selectedAlarmItem.nombre,
            current_detail: selectedAlarmItem.detalle,
            current_event: selectedAlarmItem.evento,
            current_value: selectedAlarmItem.valor,
            current_operacion: selectedAlarmItem.operacion,
            current_locations_positives: getStringFromArray(curr_loc_pos_neg.positive),
            current_locations_negatives: getStringFromArray(curr_loc_pos_neg.negative),
            current_objects_positives: getStringFromArray(curr_obj_pos_neg.positive),
            current_objects_negatives: getStringFromArray(curr_obj_pos_neg.negative),
            current_list_users: users,
            current_list_metodos: metodos,
            current_id: selectedAlarmId,
            current_activo: selectedAlarmItem.activo
        });
    }

    updateSelectedUsers = async (id, checked) => {
        const users = this.state.current_list_users.map(function (user) {
            return {
                id: user.id,
                nombre: user.nombre,
                seleccionado: Number(user.id) === Number(id) ? checked : user.seleccionado
            }
        }).sort((a, b) => {
            if (a.nombre > b.nombre) {
                return 1;
            }
            if (a.nombre < b.nombre) {
                return -1;
            }
            return 0;
        });

        this.setState({
            current_list_users: users
        });
    }

    updateSelectedMethods = async (id, checked) => {
        const metodos = this.state.current_list_metodos.map(function (metodo) {
            return {
                metodo: metodo.metodo,
                seleccionado: capitalizeFirst(metodo.metodo) === capitalizeFirst(id) ? checked : metodo.seleccionado
            }
        });

        this.setState({
            current_list_metodos: metodos
        });
    }

    setupConfirmation = (method) => {
        switch (method) {
            case "Cancelar":
            case "Guardar":
            case "Eliminar":
            case "Crear":
                this.setState({
                    confirmationTitle: method,
                    confirmationMessage: `Seguro que desea ${String(method).toLowerCase()}?`,
                    confirmationMethod: method
                });
                break;
            default:
                this.clearConfirmation();
                break;
        }
    };

    validateInputs = () => {
        if (this.state.current_name === null || this.state.current_name === "") {
            this.setAlertMessage('Debe completar el campo "Nombre"');
            return false;
        }
        if (isNaN(this.state.current_percentaje)) {
            this.setAlertMessage('El campo "Porcentaje Evaluado" debe ser numerico');
            return false;
        }
        if (this.state.current_percentaje < 1 || this.state.current_percentaje > 100) {
            this.setAlertMessage('El campo "Porcentaje Evaluado" debe estar entre 1 y 100');
            return false;
        }
        if (!this.state.current_selection) {
            this.setAlertMessage('Seccion Evaluada invalida');
            return false;
        }
        if (!this.state.current_operation) {
            this.setAlertMessage('Funcion Evaluada invalida');
            return false;
        }

        if (!this.checkField(this.state.current_event, eventoObj)) {
            return false;
        }
        if (!this.state.current_operacion) {
            this.setAlertMessage('Operacion invalida');
            return false;
        }
        if (this.state.current_value === null || this.state.current_value === "") {
            this.setAlertMessage('Debe completar el campo "Valor"');
            return false;
        }
        if (isNaN(this.state.current_value)) {
            this.setAlertMessage('El campo "Valor" debe ser numerico');
            return false;
        }
        const selectedUsers = this.state.current_list_users.filter(user => user.seleccionado);
        if (selectedUsers === null || selectedUsers.length === 0) {
            this.setAlertMessage('Debe seleccionar al menos un usuario');
            return false;
        }
        const selectedMethods = this.state.current_list_metodos.filter(metodo => metodo.seleccionado);
        if (selectedMethods === null || selectedMethods.length === 0) {
            this.setAlertMessage('Debe seleccionar al menos un metodo de envio');
            return false;
        }
        return true;
    }

    handleCancelRequest = () => {
        this.setupConfirmation("Cancelar");
    }

    handleSaveRequest = () => {
        this.setupConfirmation("Guardar");
    }

    handleDeleteRequest = () => {
        this.setupConfirmation("Eliminar");
    }

    handleCreateRequest = () => {
        this.setupConfirmation("Crear");
    }

    handleOkConfirmation = async (e) => {
        this.clearConfirmation();
        this.setState({
            loading: true
        });

        switch (this.state.confirmationMethod) {
            case "Cancelar":
                this.clearSelection();
                break;
            case "Guardar":
                if (this.validateInputs()) {
                    const selectedUsers = this.state.current_list_users.filter(user => user.seleccionado);
                    const selectedMethods = this.state.current_list_metodos.filter(metodo => metodo.seleccionado);
                    const lugares = joinAndPrefixArrays(getArrayFromString(this.state.current_locations_positives), getArrayFromString(this.state.current_locations_negatives));
                    const objetos = joinAndPrefixArrays(getArrayFromString(this.state.current_objects_positives), getArrayFromString(this.state.current_objects_negatives));

                    const updateConfiguracionAlarmas = {
                        nombre: this.state.current_name,
                        detalle: this.state.current_detail,
                        evento: this.state.current_event,
                        valor: this.state.current_value,
                        operacion: this.state.current_operacion,
                        lugares: lugares,
                        objetos: objetos,
                        notificarUsuarios: selectedUsers.map(user => Number(user.id)),
                        metodosDeEnvio: selectedMethods.map(metodo => String(metodo.metodo)),
                        porcentajeEvaluado: this.state.current_percentaje,
                        seccionEvaluada: this.state.current_selection,
                        operacionEvaluada: this.state.current_operation,
                        activo: this.state.current_activo
                    };
                    const requestOptions = {
                        method: 'PUT',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(updateConfiguracionAlarmas)
                    };
                    const response = await fetch(`Main/UpdateAlarmGroupConfig/${this.state.loggedUser.empresa.id}/${this.state.current_id}`, requestOptions);
                    this.setState({
                        alarms: await this.getAlarmConfigs(this.state.loggedUser.empresa.id),
                    });

                    if (response.ok) {
                        this.setSuccessMessage('Se ha actualizado la alarma correctamente.')
                        this.clearSelection();
                    }
                    else {
                        this.setAlertMessage('No pudimos actualizar la alarma.');
                    }
                }
                break;
            case "Eliminar":
                const requestOptionsDelete = {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify()
                };
                const responseDelete = await fetch(`Main/DeleteAlarmGroupConfig/${this.state.loggedUser.empresa.id}/${this.state.current_id}`, requestOptionsDelete);
                this.setState({
                    alarms: await this.getAlarmConfigs(this.state.loggedUser.empresa.id),
                    loading: false
                });

                if (responseDelete.ok) {
                    this.setSuccessMessage('Se ha eliminado la alarma correctamente.')
                    this.clearSelection();
                }
                else {
                    this.setAlertMessage('No pudimos eliminar la alarma.');
                }
                break;
            case "Crear":
                if (this.validateInputs()) {
                    const selectedUsers = this.state.current_list_users.filter(user => user.seleccionado);
                    const selectedMethods = this.state.current_list_metodos.filter(metodo => metodo.seleccionado);
                    const lugares = joinAndPrefixArrays(getArrayFromString(this.state.current_locations_positives), getArrayFromString(this.state.current_locations_negatives));
                    const objetos = joinAndPrefixArrays(getArrayFromString(this.state.current_objects_positives), getArrayFromString(this.state.current_objects_negatives));

                    const createConfiguracionAlarmas = {
                        nombre: this.state.current_name,
                        detalle: this.state.current_detail,
                        evento: this.state.current_event,
                        valor: this.state.current_value,
                        operacion: this.state.current_operacion,
                        lugares: lugares,
                        objetos: objetos,
                        notificarUsuarios: selectedUsers.map(user => Number(user.id)),
                        metodosDeEnvio: selectedMethods.map(metodo => String(metodo.metodo)),
                        porcentajeEvaluado: this.state.current_percentaje,
                        seccionEvaluada: this.state.current_selection,
                        operacionEvaluada: this.state.current_operation,
                        activo: this.state.current_activo
                    };

                    const requestOptionsCreate = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(createConfiguracionAlarmas)
                    };
                    const responseCreate = await fetch(`Main/CreateAlarmGroupConfig/${this.state.loggedUser.empresa.id}/${this.state.selectedUser_id}`, requestOptionsCreate);
                    this.setState({
                        alarms: await this.getAlarmConfigs(this.state.loggedUser.empresa.id),
                        loading: false
                    });

                    if (responseCreate.ok) {
                        this.setSuccessMessage('Se ha creado la alarma correctamente.')
                        this.clearSelection();
                    }
                    else {
                        this.setAlertMessage('No pudimos crear la alarma.');
                    }
                }
                break;
            default:
                this.clearSelection();
                break;
        }
        this.setState({
            loading: false
        });
    };

    handleCancelConfirmation = () => {
        this.clearConfirmation();
    };

    addNewUser = async () => {
        const users = this.state.users.map(function (user) {
            return {
                id: user.id,
                nombre: user.nombre,
                seleccionado: false
            }
        }).sort((a, b) => {
            if (a.nombre > b.nombre) {
                return 1;
            }
            if (a.nombre < b.nombre) {
                return -1;
            }
            return 0;
        });

        const metodos = this.state.metodosEnvio.map(function (metodo) {
            return {
                metodo: metodo,
                seleccionado: false
            }
        });

        this.setState({
            current_id: 0,
            current_name: '',
            current_detail: '',
            current_event: '',
            current_value: '',
            current_operacion: 'Greater',
            current_locations_positives: '',
            current_locations_negatives: '',
            current_objects_positives: '',
            current_objects_negatives: '',
            current_list_metodos: metodos,
            current_list_users: users,
        });
    }

    getMainPage = () => {
        return (
            <div style={{ textAlign: "center" }}>
                {this.getMessageArea()}
                {<SimpleConfirmationComponent title={this.state.confirmationTitle} message={this.state.confirmationMessage} btnOkText="Si" btnCancelText="No" onOk={this.handleOkConfirmation} onCancel={this.handleCancelConfirmation} onClose={this.handleCancelConfirmation} />}
                <table className='table table-striped mini-table'>
                    <thead>
                        <tr>
                            <th>Detalle Alarmas Agrupadas</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.alarms.map(alarm =>
                            <tr key={alarm.id} onClick={() => this.rowClickHandler(alarm.id)}>
                                <td className='infobox'>
                                    {getFullGroupedMessage(alarm)}
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td colSpan="1">
                                <BsFillPlusCircleFill style={{ fontSize: "2rem", color: "var(--blueColor)" }} className="update-input-data-btn" alt="Agregar" title="Agregar" onClick={this.addNewUser}></BsFillPlusCircleFill>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    getEditPage = () => {
        return (
            <div style={{ textAlign: "center" }}>
                {this.getMessageArea()}
                {<SimpleConfirmationComponent title={this.state.confirmationTitle} message={this.state.confirmationMessage} btnOkText="Si" btnCancelText="No" onOk={this.handleOkConfirmation} onCancel={this.handleCancelConfirmation} onClose={this.handleCancelConfirmation} />}
                <table className='table table-striped mini-table'>
                    <thead>
                        <tr>
                            <th colSpan="3">
                                Editar Alarma
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <b>Nombre:</b>
                            </td>
                            <td>
                                <input type="text" minLength={1} maxLength={50} className="single-input" style={{ textAlign: "center" }} value={this.state.current_name} onChange={(e) => this.setState({ current_name: e.target.value })}></input>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Detalle:</b>
                            </td>
                            <td>
                                <input type="text" minLength={1} maxLength={150} className="single-input" style={{ textAlign: "center" }} value={this.state.current_detail} onChange={(e) => this.setState({ current_detail: e.target.value })}></input>
                            </td>
                            <td>
                                &nbsp;
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Porcentaje Evaluado:</b>
                            </td>
                            <td>
                                <input type="number" minLength={1} maxLength={100} className="single-input" style={{ textAlign: "center" }} value={this.state.current_percentaje} onChange={(e) => this.setState({ current_percentaje: e.target.value })}></input>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Seccion Evaluada:</b>
                            </td>
                            <td>
                                <select id="cboAlarmSelection" defaultValue={this.state.current_selection} onChange={(e) => this.setState({ current_selection: e.target.value })}>
                                    {this.state.alarmSelection.map(operacion =>
                                        <option key={operacion.valor} value={operacion.valor}>{operacion.nombre}</option>
                                    )}
                                </select>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Funcion Evaluada:</b>
                            </td>
                            <td>
                                <select id="cboAlarmEvaluation" defaultValue={this.state.current_operation} onChange={(e) => this.setState({ current_operation: e.target.value })}>
                                    {this.state.alarmEvaluation.map(operacion =>
                                        <option key={operacion.valor} value={operacion.valor}>{operacion.nombre}</option>
                                    )}
                                </select>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Evento:</b>
                            </td>
                            <td>
                                <input type="text" minLength={1} maxLength={150} className="single-input" style={{ textAlign: "center" }} value={this.state.current_event} onChange={(e) => this.setState({ current_event: e.target.value })}></input>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Operacion:</b>
                            </td>
                            <td>
                                <select id="cboOperciones" defaultValue={this.state.current_operacion} onChange={(e) => this.setState({ current_operacion: e.target.value })}>
                                    {this.state.operaciones.map(operacion =>
                                        <option key={operacion.valor} value={operacion.valor}>{operacion.nombre}</option>
                                    )}
                                </select>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Valor:</b>
                            </td>
                            <td>
                                <input type="number" minLength={1} maxLength={9} className="single-input" style={{ textAlign: "center" }} value={this.state.current_value} onChange={(e) => this.setState({ current_value: e.target.value })}></input>
                                <br />
                                <label style={{ fontSize: "10px", color: "var(--bs-secondary)" }}>{secondsToHours(this.state.current_value)}</label>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Lugares (Incluir):</b>
                            </td>
                            <td>
                                <input type="text" minLength={0} maxLength={150} className="single-input" style={{ textAlign: "center" }} value={this.state.current_locations_positives} onChange={(e) => this.setState({ current_locations_positives: e.target.value })}></input>
                                <br />
                                <label style={{ fontSize: "10px", color: "var(--bs-secondary)" }}>(valores separados por coma (opcional), si se deja vacio no se filtrara por esta propiedad)</label>
                            </td>
                            <td>
                                {getEmptyIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Lugares (Excluir):</b>
                            </td>
                            <td>
                                <input type="text" minLength={0} maxLength={150} className="single-input" style={{ textAlign: "center" }} value={this.state.current_locations_negatives} onChange={(e) => this.setState({ current_locations_negatives: e.target.value })}></input>
                                <br />
                                <label style={{ fontSize: "10px", color: "var(--bs-secondary)" }}>(valores separados por coma (opcional), si se deja vacio no se filtrara por esta propiedad)</label>
                            </td>
                            <td>
                                {getEmptyIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Objetos (Incluir):</b>
                            </td>
                            <td>
                                <input type="text" minLength={0} maxLength={150} className="single-input" style={{ textAlign: "center" }} value={this.state.current_objects_positives} onChange={(e) => this.setState({ current_objects_positives: e.target.value })}></input>
                                <br />
                                <label style={{ fontSize: "10px", color: "var(--bs-secondary)" }}>(valores separados por coma (opcional), si se deja vacio no se filtrara por esta propiedad)</label>
                            </td>
                            <td>
                                {getEmptyIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Objetos (Excluir):</b>
                            </td>
                            <td>
                                <input type="text" minLength={0} maxLength={150} className="single-input" style={{ textAlign: "center" }} value={this.state.current_objects_negatives} onChange={(e) => this.setState({ current_objects_negatives: e.target.value })}></input>
                                <br />
                                <label style={{ fontSize: "10px", color: "var(--bs-secondary)" }}>(valores separados por coma (opcional), si se deja vacio no se filtrara por esta propiedad)</label>
                            </td>
                            <td>
                                {getEmptyIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Usuarios:</b>
                            </td>
                            <td>
                                <div style={{ height: "fit-content", maxHeight: "5rem", minHeight: "1rem", border: "1px solid black", width: "100%", minWidth: "100px", maxWidth: "250px", margin: "auto", overflowY: "auto" }}>
                                    {this.state.current_list_users.map(user =>
                                        <p key={user.id} style={{ textAlign: "left", marginTop: "2px", marginBottom: "2px" }}>
                                            <input style={{ marginLeft: "10px", marginTop: "0px", marginBottom: "0px", textAlign: "center" }} type="checkbox" className="single-input" checked={user.seleccionado} onChange={(e) => this.updateSelectedUsers(user.id, Boolean(e.target.checked))}></input>
                                            <label style={{ marginLeft: "10px", marginTop: "0px", marginBottom: "0px" }}>{user.nombre}</label>
                                        </p>
                                    )}
                                </div>
                                <label style={{ fontSize: "10px", color: "var(--bs-secondary)" }}>(requerida al menos una opcion)</label>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Metodos:</b>
                            </td>
                            <td>
                                <div style={{ height: "fit-content", maxHeight: "5rem", minHeight: "1rem", border: "1px solid black", width: "100%", minWidth: "100px", maxWidth: "250px", margin: "auto", overflowY: "auto" }}>
                                    {this.state.current_list_metodos.map(metodo =>
                                        <p key={metodo.metodo} style={{ textAlign: "left", marginTop: "2px", marginBottom: "2px" }}>
                                            <input style={{ marginLeft: "10px", marginTop: "0px", marginBottom: "0px", textAlign: "center" }} type="checkbox" className="single-input" checked={metodo.seleccionado} onChange={(e) => this.updateSelectedMethods(metodo.metodo, Boolean(e.target.checked))}></input>
                                            <label style={{ marginLeft: "10px", marginTop: "0px", marginBottom: "0px" }}>{metodo.metodo}</label>
                                        </p>
                                    )}
                                </div>
                                <label style={{ fontSize: "10px", color: "var(--bs-secondary)" }}>(requerida al menos una opcion)</label>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Activa:</b>
                            </td>
                            <td>
                                <input type="checkbox" className="single-input" style={{ textAlign: "center" }} checked={this.state.current_activo} onChange={(e) => this.setState({ current_activo: Boolean(e.target.checked) })}></input>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3">
                                <BsFillCheckCircleFill style={{ fontSize: "2rem", color: "var(--bs-success)" }} className="update-input-data-btn" alt="Guardar" title="Guardar" onClick={this.handleSaveRequest}></BsFillCheckCircleFill>
                                <BsFillXCircleFill style={{ fontSize: "2rem", color: "var(--bs-warning)" }} className="update-input-data-btn" alt="Cancelar" title="Cancelar" onClick={this.handleCancelRequest}></BsFillXCircleFill>
                                <BsTrashFill style={{ fontSize: "2rem", color: "var(--bs-danger)" }} className="update-input-data-btn" alt="Eliminar" title="Eliminar" onClick={this.handleDeleteRequest}></BsTrashFill>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    getCreatePage = () => {
        return (
            <div style={{ textAlign: "center" }}>
                {this.getMessageArea()}
                {<SimpleConfirmationComponent title={this.state.confirmationTitle} message={this.state.confirmationMessage} btnOkText="Si" btnCancelText="No" onOk={this.handleOkConfirmation} onCancel={this.handleCancelConfirmation} onClose={this.handleCancelConfirmation} />}
                <table className='table table-striped mini-table'>
                    <thead>
                        <tr>
                            <th colSpan="3">
                                Crear Alarma
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <b>Nombre:</b>
                            </td>
                            <td>
                                <input type="text" minLength={1} maxLength={50} className="single-input" style={{ textAlign: "center" }} value={this.state.current_name} onChange={(e) => this.setState({ current_name: e.target.value })}></input>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Detalle:</b>
                            </td>
                            <td>
                                <input type="text" minLength={1} maxLength={150} className="single-input" style={{ textAlign: "center" }} value={this.state.current_detail} onChange={(e) => this.setState({ current_detail: e.target.value })}></input>
                            </td>
                            <td>
                                &nbsp;
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Porcentaje Evaluado:</b>
                            </td>
                            <td>
                                <input type="number" minLength={1} maxLength={100} className="single-input" style={{ textAlign: "center" }} value={this.state.current_percentaje} onChange={(e) => this.setState({ current_percentaje: e.target.value })}></input>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Seccion Evaluada:</b>
                            </td>
                            <td>
                                <select id="cboAlarmSelection" defaultValue={this.state.current_selection} onChange={(e) => this.setState({ current_selection: e.target.value })}>
                                    {this.state.alarmSelection.map(operacion =>
                                        <option key={operacion.valor} value={operacion.valor}>{operacion.nombre}</option>
                                    )}
                                </select>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Funcion Evaluada:</b>
                            </td>
                            <td>
                                <select id="cboAlarmEvaluation" defaultValue={this.state.current_operation} onChange={(e) => this.setState({ current_operation: e.target.value })}>
                                    {this.state.alarmEvaluation.map(operacion =>
                                        <option key={operacion.valor} value={operacion.valor}>{operacion.nombre}</option>
                                    )}
                                </select>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Evento:</b>
                            </td>
                            <td>
                                <input type="text" minLength={1} maxLength={150} className="single-input" style={{ textAlign: "center" }} value={this.state.current_event} onChange={(e) => this.setState({ current_event: e.target.value })}></input>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Operacion:</b>
                            </td>
                            <td>
                                <select id="cboOperciones" defaultValue={this.state.current_operacion} onChange={(e) => this.setState({ current_operacion: e.target.value })}>
                                    {this.state.operaciones.map(operacion =>
                                        <option key={operacion.valor} value={operacion.valor}>{operacion.nombre}</option>
                                    )}
                                </select>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Valor:</b>
                            </td>
                            <td>
                                <input type="number" minLength={1} maxLength={9} className="single-input" style={{ textAlign: "center" }} value={this.state.current_value} onChange={(e) => this.setState({ current_value: e.target.value })}></input>
                                <br />
                                <label style={{ fontSize: "10px", color: "var(--bs-secondary)" }}>{secondsToHours(this.state.current_value)}</label>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Lugares (Incluir):</b>
                            </td>
                            <td>
                                <input type="text" minLength={0} maxLength={150} className="single-input" style={{ textAlign: "center" }} value={this.state.current_locations_positives} onChange={(e) => this.setState({ current_locations_positives: e.target.value })}></input>
                                <br />
                                <label style={{ fontSize: "10px", color: "var(--bs-secondary)" }}>(valores separados por coma (opcional), si se deja vacio no se filtrara por esta propiedad)</label>
                            </td>
                            <td>
                                {getEmptyIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Lugares (Excluir):</b>
                            </td>
                            <td>
                                <input type="text" minLength={0} maxLength={150} className="single-input" style={{ textAlign: "center" }} value={this.state.current_locations_negatives} onChange={(e) => this.setState({ current_locations_negatives: e.target.value })}></input>
                                <br />
                                <label style={{ fontSize: "10px", color: "var(--bs-secondary)" }}>(valores separados por coma (opcional), si se deja vacio no se filtrara por esta propiedad)</label>
                            </td>
                            <td>
                                {getEmptyIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Objetos (Incluir):</b>
                            </td>
                            <td>
                                <input type="text" minLength={0} maxLength={150} className="single-input" style={{ textAlign: "center" }} value={this.state.current_objects_positives} onChange={(e) => this.setState({ current_objects_positives: e.target.value })}></input>
                                <br />
                                <label style={{ fontSize: "10px", color: "var(--bs-secondary)" }}>(valores separados por coma (opcional), si se deja vacio no se filtrara por esta propiedad)</label>
                            </td>
                            <td>
                                {getEmptyIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Objetos (Excluir):</b>
                            </td>
                            <td>
                                <input type="text" minLength={0} maxLength={150} className="single-input" style={{ textAlign: "center" }} value={this.state.current_objects_negatives} onChange={(e) => this.setState({ current_objects_negatives: e.target.value })}></input>
                                <br />
                                <label style={{ fontSize: "10px", color: "var(--bs-secondary)" }}>(valores separados por coma (opcional), si se deja vacio no se filtrara por esta propiedad)</label>
                            </td>
                            <td>
                                {getEmptyIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Usuarios:</b>
                            </td>
                            <td>
                                <div style={{ height: "fit-content", maxHeight: "5rem", minHeight: "1rem", border: "1px solid black", width: "100%", minWidth: "100px", maxWidth: "250px", margin: "auto", overflowY: "auto" }}>
                                    {this.state.current_list_users.map(user =>
                                        <p key={user.id} style={{ textAlign: "left", marginTop: "2px", marginBottom: "2px" }}>
                                            <input style={{ marginLeft: "10px", marginTop: "0px", marginBottom: "0px", textAlign: "center" }} type="checkbox" className="single-input" checked={user.seleccionado} onChange={(e) => this.updateSelectedUsers(user.id, Boolean(e.target.checked))}></input>
                                            <label style={{ marginLeft: "10px", marginTop: "0px", marginBottom: "0px" }}>{user.nombre}</label>
                                        </p>
                                    )}
                                </div>
                                <label style={{ fontSize: "10px", color: "var(--bs-secondary)" }}>(requerida al menos una opcion)</label>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Metodos:</b>
                            </td>
                            <td>
                                <div style={{ height: "fit-content", maxHeight: "5rem", minHeight: "1rem", border: "1px solid black", width: "100%", minWidth: "100px", maxWidth: "250px", margin: "auto", overflowY: "auto" }}>
                                    {this.state.current_list_metodos.map(metodo =>
                                        <p key={metodo.metodo} style={{ textAlign: "left", marginTop: "2px", marginBottom: "2px" }}>
                                            <input style={{ marginLeft: "10px", marginTop: "0px", marginBottom: "0px", textAlign: "center" }} type="checkbox" className="single-input" checked={metodo.seleccionado} onChange={(e) => this.updateSelectedMethods(metodo.metodo, Boolean(e.target.checked))}></input>
                                            <label style={{ marginLeft: "10px", marginTop: "0px", marginBottom: "0px" }}>{metodo.metodo}</label>
                                        </p>
                                    )}
                                </div>
                                <label style={{ fontSize: "10px", color: "var(--bs-secondary)" }}>(requerida al menos una opcion)</label>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Activa:</b>
                            </td>
                            <td>
                                <input type="checkbox" className="single-input" style={{ textAlign: "center" }} checked={this.state.current_activo} onChange={(e) => this.setState({ current_activo: Boolean(e.target.checked) })}></input>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3">
                                <BsFillCheckCircleFill style={{ fontSize: "2rem", color: "var(--bs-success)" }} className="update-input-data-btn" alt="Crear" title="Crear" onClick={this.handleCreateRequest}></BsFillCheckCircleFill>
                                <BsFillXCircleFill style={{ fontSize: "2rem", color: "var(--bs-warning)" }} className="update-input-data-btn" alt="Cancelar" title="Cancelar" onClick={this.handleCancelRequest}></BsFillXCircleFill>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    getLandingPage = () => {
        return (this.state.current_id == null ? this.getMainPage() : this.state.current_id === 0 ? this.getCreatePage() : this.getEditPage());
    };

    render() {
        return this.state.loading ? this.getRender() : this.state.isAdminUser ? this.getLandingPage() : this.getUnauthorizedPage();
    }
}
