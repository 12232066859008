import { Home } from "./pages/Home";
import { Reports } from "./pages/Reports";
import { ReportsUsers } from "./pages/Reports/Users";
import { ReportsAlarms } from "./pages/Reports/Alarms";
import { ReportsGroupedAlarms } from "./pages/Reports/GroupedAlarms";

import { Config } from "./pages/Config";
import { ConfigUsers } from "./pages/Config/Users";
import { ConfigAlarms } from "./pages/Config/Alarms";
import { ConfigGroupedAlarms } from "./pages/Config/GroupedAlarms";
import { Profile } from "./pages/Profile";
import { IsUserAdmin } from './utils';

export const UserAppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/reports',
        element: <Reports />
    },
    {
        path: '/reports/Alarms',
        element: <ReportsAlarms />
    },
    {
        path: '/reports/GroupedAlarms',
        element: <ReportsGroupedAlarms />
    },
    {
        path: '/profile',
        element: <Profile />
    }
];

export const AdminAppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/reports',
        element: <Reports />
    },
    {
        path: '/reports/users',
        element: <ReportsUsers />
    },
    {
        path: '/reports/Alarms',
        element: <ReportsAlarms />
    },
    {
        path: '/reports/GroupedAlarms',
        element: <ReportsGroupedAlarms />
    },
    {
        path: '/config',
        element: <Config />
    },
    {
        path: '/config/users',
        element: <ConfigUsers />
    },
    {
        path: '/config/alarms',
        element: <ConfigAlarms />
    },
    {
        path: '/config/groupedAlarms',
        element: <ConfigGroupedAlarms />
    },
    {
        path: '/profile',
        element: <Profile />
    }
];

export function SelectRoutes()
{
    if (IsUserAdmin()) {
        return AdminAppRoutes;
    }
    else
    {
        return UserAppRoutes;
    }
}
