import React, { useEffect, useState } from 'react';
import { productInfo } from './App';
import { SimpleAlertComponent, SimpleSuccessComponent } from './components/Alerts';
import './custom.css';
import './login.css';
import { setSessionVariable, storedSession, validateField, empresaObj, usuarioObj, claveObj, codigoVerificacionObj } from './utils';

function LoginComponent({ setAuthenticated }) {
    const [loading, setLoading] = useState(false);
    const [recuperoActivo, setRecuperoActivo] = useState(false);
    const [fixingWhatsapp, setFixingWhatsapp] = useState(false);
    const [whatsappNumber, setWhatsappNumber] = useState('');
    const [whatsappNumberLink, setWhatsappNumberLink] = useState('');
    const [recuperoConfigurado, setRecuperoConfigurado] = useState(false);
    const [codigoEnviado, setCodigoEnviado] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [company, setCompany] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [recoverMethod, setRecoverMethod] = useState('SMS');
    const [verifiedOperation, setVerifiedOperation] = useState('Blanqueo');
    const [receivedCode, setReceivedCode] = useState('');
    const [newPasword, setNewPasword] = useState('');
    const [confirmNewPasword, setConfirmNewPasword] = useState('');

    useEffect(() => {
        document.documentElement.classList.add('loginHtml')
        return () => {
            document.documentElement.classList.remove('loginHtml')
        }
    }, [])

    const handleAlertClose = () => {
        setAlertMessage('');
    };

    const handleSuccesClose = () => {
        setSuccessMessage('');
    };

    const getMessageArea = () => {
        return (
            <div>
                <SimpleAlertComponent message={alertMessage} onClose={handleAlertClose} />
                <SimpleSuccessComponent message={successMessage} onClose={handleSuccesClose} />
            </div>
        );
    };

    const clearVars = () => {
        setCompany('');
        setUsername('');
        setPassword('');
        setRecoverMethod('SMS');
        setVerifiedOperation('Blanqueo');
        setReceivedCode('');
        setNewPasword('');
        setConfirmNewPasword('');
        setWhatsappNumber('');
        setWhatsappNumberLink('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!checkField(company, empresaObj)) {
            return false;
        }
        if (!checkField(username, usuarioObj)) {
            return false;
        }
        if (!checkField(password, claveObj)) {
            return false;
        }

        setLoading(true);
        const randomId = crypto.randomUUID();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ empresa: company, usuario: username, clave: btoa(password), uid: encodeURIComponent(randomId) })
        };
        const response = await fetch(`Login`, requestOptions);
        setLoading(false);

        if (response.ok) {
            const loggedUser = await response.json();
            loggedUser.publicKey = password;
            setSessionVariable(storedSession.sessionName, loggedUser);

            setAuthenticated(true);
            return true;
        } else {
            if (response.status === 500) {
                setAlertMessage(`No se pudo iniciar sesion. Aguarde unos instantes e intente nuevamente.`);
                return false;
            }
            const responseObj = await response.json();
            clearVars();
            setAlertMessage(responseObj.detail);
            return false;
        }
    };

    const checkField = (inputValue, validationObj) => {
        const validationResult = validateField(inputValue, validationObj);
        if (validationResult === '') {
            return true;
        }
        setAlertMessage(validationResult);
        return false;
    };

    const recuperarClave = () => {
        clearVars();
        setRecuperoActivo(true);
        setRecuperoConfigurado(false);
        setCodigoEnviado(false);
    };

    const volverLogin = () => {
        clearVars();
        setRecuperoActivo(false);
        setFixingWhatsapp(false);
        setRecuperoConfigurado(false);
        setCodigoEnviado(false);
    };

    const getWhatsappFixNumber = async (e) => {
        setLoading(true);
        const response = await fetch(`Main/GetContactNumber?Type=${0}`);
        setLoading(false);

        if (!response.ok) {
            return "";
        }
        const returnedValue = await response.text();
        return returnedValue;
    };

    const fixWhatsapp = () => {
        getWhatsappFixNumber().then(result => {
            clearVars();
            setWhatsappNumber(String(result));
            setWhatsappNumberLink(`https://wa.me/${String(result)}?text=Este%20es%20un%20mensaje%20de%20vinculacion%20de%20cuenta.`);
            setFixingWhatsapp(true);
            setRecuperoConfigurado(false);
            setCodigoEnviado(false);
        }
        );
    };

    const handleOperationMethodChange = (target) => {
        if (String(target.currentTarget.value).toLowerCase() === 'Whatsapp'.toLowerCase()) {
            fixWhatsapp();
            return;
        }
        setVerifiedOperation(target.currentTarget.value);
    };

    const handleRecoverMethodChange = (target) => {
        setRecoverMethod(target.currentTarget.value);
    };

    const validateRecoveryFields = () => {
        if (!checkField(company, empresaObj)) {
            return false;
        }
        if (!checkField(username, usuarioObj)) {
            return false;
        }
        return true;
    };

    const handleGotTokenRequest = () => {
        if (!validateRecoveryFields()) {
            return false;
        }
        setCodigoEnviado(true);
    };

    const handleRecovery = async (e) => {
        e.preventDefault();
        if (!validateRecoveryFields()) {
            return false;
        }
        setRecuperoConfigurado(true);
    };

    const handleRecoveryCode = async (e) => {
        e.preventDefault();
        if (!validateRecoveryFields()) {
            return false;
        }

        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify()
        };
        const response = await fetch(`Login/Recover/${company}/${username}?Metodo=${recoverMethod}`, requestOptions);
        setLoading(false);

        if (!response.ok) {
            if (response.status === 500) {
                setAlertMessage(`No se pudo verificar los datos. Aguarde unos instantes e intente nuevamente.`);
                return false;
            }
            const responseObj = await response.json();
            setAlertMessage(responseObj.detail);
            return false;
        }
        setCodigoEnviado(true);
    };

    const handleRecoveryConfirmation = async (e) => {
        e.preventDefault();
        if (!checkField(receivedCode, codigoVerificacionObj)) {
            return false;
        }
        var confirmedPassword = verifiedOperation.toLowerCase();
        if (verifiedOperation.toLowerCase() === 'Blanqueo'.toLowerCase()) {
            if (!checkField(newPasword, claveObj)) {
                return false;
            }
            if (confirmNewPasword !== newPasword) {
                setAlertMessage(`Las claves no coinciden.`);
                return false;
            }
            confirmedPassword = newPasword;
        }

        setLoading(true);
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify()
        };
        const response = await fetch(`Login/Verify/${company}/${username}?Codigo=${receivedCode}&Clave=${btoa(confirmedPassword)}&Operacion=${encodeURIComponent(verifiedOperation)}`, requestOptions);
        setLoading(false);

        if (!response.ok) {
            if (response.status === 500) {
                setAlertMessage(`No se pudo realizar la operacion '${verifiedOperation}'. Aguarde unos instantes e intente nuevamente.`);
                return false;
            }
            const responseObj = await response.json();
            setReceivedCode('');
            setNewPasword('');
            setConfirmNewPasword('');
            setAlertMessage(responseObj.detail);
            return false;
        }
        clearVars();
        setRecuperoActivo(false);
        setRecuperoConfigurado(false);
        setCodigoEnviado(false);
        setSuccessMessage(`Operacion '${verifiedOperation}' procesada correctamente, intente iniciar sesion nuevamente.`);
        return true;
    };

    const getLandingPage = () => {
        return (
            <form onSubmit={handleSubmit}>
                <div className="container loginContainer">
                    <h1>{productInfo.appName}</h1>
                    <br />
                    <label>{empresaObj.label}</label>
                    <br />
                    <input
                        type="text"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                    />
                    <br />
                    <label>{usuarioObj.label}</label>
                    <br />
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <br />
                    <label>{claveObj.label}</label>
                    <br />
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <br />
                    <button type="submit">Login</button>
                    <br />
                    <label className="recoverPassword" onClick={recuperarClave}>Necesito ayuda</label>
                    {getMessageArea()}
                </div>
            </form>
        );
    };

    const getRecoveryFields = () => {
        return (
            <form onSubmit={handleRecovery}>
                <div className="container loginContainer">
                    <h1>{productInfo.appName}</h1>
                    <br />
                    <label>Operacion</label>
                    <br />
                    <select defaultValue={verifiedOperation} onChange={handleOperationMethodChange}>
                        <option value='Blanqueo'>Blanqueo Clave</option>
                        <option value='Limpieza'>Limpieza Sesiones</option>
                        {/*<option value='Whatsapp'>Ayuda con Whatsapp</option>*/}
                    </select>
                    <br />
                    <label>{empresaObj.label}</label>
                    <br />
                    <input
                        type="text"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                    />
                    <br />
                    <label>{usuarioObj.label}</label>
                    <br />
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <br />
                    <button type="submit">Siguiente</button>
                    <br />
                    <label className="recoverPassword" onClick={volverLogin}>Volver</label>
                    {getMessageArea()}
                </div>
            </form>
        );
    };

    const getWhatsappFields = () => {
        return (
            <form onSubmit={handleRecovery}>
                <div className="container loginContainer">
                    <h1>{productInfo.appName}</h1>
                    <br />
                    <label>Ayuda con Whatsapp</label>
                    <br />
                    <br />
                    {whatsappNumber.length === 0 &&
                        <div>
                            <p>Para recibir mensajes de whatsapp, debe previamente y por unica vez mandar un mensaje a nuestra linea de atencion al cliente</p>
                        </div>
                    }
                    {whatsappNumber.length > 0 &&
                        <div>
                            <p>Para recibir mensajes de whatsapp, debe previamente y por unica vez mandar un mensaje a nuestra linea de atencion al cliente: <b>'{whatsappNumber}'</b></p>
                            <br />
                            <div className="whatsappContactDiv">
                                <p>Puedes hacer click en el icono y te contactaremos directamente</p>
                                <br />
                                <a aria-label="Mensaje de vinculacion de Whatsapp" href={whatsappNumberLink} target="_blank" rel="noreferrer">
                                    <img className="whatsappContactIcon" alt="Mensaje de vinculacion de Whatsapp" src="images/whatsapp.png" />
                                </a>
                            </div>
                        </div>
                    }
                    <br />
                    <label className="recoverPassword" onClick={volverLogin}>Salir</label>
                    {getMessageArea()}
                </div>
            </form>
        );
    };

    const getCodeRetrieval = () => {
        return (
            <form onSubmit={handleRecoveryCode}>
                <div className="container loginContainer">
                    <h1>{productInfo.appName}</h1>
                    <br />
                    <p>La operacion de {verifiedOperation} requiere un codigo de validacion</p>
                    <p>El mismo tiene una hora de validez y puede usarlo para cualquier operacion que lo requira</p>
                    <br />
                    <label>Tipo Recupero</label>
                    <br />
                    <select defaultValue={recoverMethod} onChange={handleRecoverMethodChange}>
                        <option value='Mail'>Mail</option>
                      {/*  <option value='WhatsApp'>WhatsApp</option>*/}
                        <option value='SMS'>SMS</option>
                    </select>
                    <br />
                    <button type="submit">Solicitar codigo</button>
                    <br />
                    <button type="button" className="loginSkipButton" onClick={handleGotTokenRequest}>Ya tengo un codigo</button>
                    <br />
                    <label className="recoverPassword" onClick={volverLogin}>Salir</label>
                    {getMessageArea()}
                </div>
            </form>
        );
    };

    const getVerifyFields = () => {
        if (verifiedOperation.toLowerCase() === 'Blanqueo'.toLowerCase()) {
            return (
                <form onSubmit={handleRecoveryConfirmation}>
                    <div className="container loginContainer">
                        <h1>{productInfo.appName}</h1>
                        <br />
                        <label>Ingrese el codigo recibido</label>
                        <br />
                        <input
                            type="text"
                            value={receivedCode}
                            onChange={(e) => setReceivedCode(e.target.value)}
                        />
                        <br />
                        <label>Nueva clave</label>
                        <br />
                        <input
                            type="password"
                            value={newPasword}
                            onChange={(e) => setNewPasword(e.target.value)}
                        />
                        <br />
                        <label>Confirmar nueva clave</label>
                        <br />
                        <input
                            type="password"
                            value={confirmNewPasword}
                            onChange={(e) => setConfirmNewPasword(e.target.value)}
                        />
                        <br />
                        <button type="submit">Cambiar Clave</button>
                        <br />
                        <label className="recoverPassword" onClick={volverLogin}>Salir</label>
                        {getMessageArea()}
                    </div>
                </form>
            );
        } else {
            return (
                <form onSubmit={handleRecoveryConfirmation}>
                    <div className="container loginContainer">
                        <h1>{productInfo.appName}</h1>
                        <br />
                        <label>Ingrese el codigo recibido</label>
                        <br />
                        <input
                            type="text"
                            value={receivedCode}
                            onChange={(e) => setReceivedCode(e.target.value)}
                        />
                        <br />
                        <button type="submit">Limpiar Sesiones</button>
                        <br />
                        <label className="recoverPassword" onClick={volverLogin}>Salir</label>
                        {getMessageArea()}
                    </div>
                </form>
            );
        }
    };

    const getRender = () => {
        return (
            <div className="spinner-border text-white" role="status">
                &nbsp;
            </div>
        );
    }

    if (loading) {
        return (getRender());
    }

    if (recuperoActivo) {
        if (fixingWhatsapp) {
            return (getWhatsappFields());
        }
        if (recuperoConfigurado) {
            if (codigoEnviado) {
                return (getVerifyFields());
            }
            return (getCodeRetrieval());
        }
        return (getRecoveryFields());
    }
    return (getLandingPage());
}

export default LoginComponent;