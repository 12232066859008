import { React } from 'react';
import { Shared } from '../../components/Shared';
import { getRowIcon, getUserFromId, getConfigFromId, orderReportData, getUsers, getEvents, getGroupedAlarmConfigs, IsUserAdmin, capitalizeFirst, convertHoursToSeconds, exportToExcel, formatDateToCustomString, getCurrentSession, getDateTimeDifferenceInMinutes, getFechaDesde, getFechaHasta, getFechaMaxima, getFechaMinima, getMetodosEnvio, getStringFromArray, secondsToHours } from '../../utils';
export class ReportsGroupedAlarms extends Shared {
    static displayName = ReportsGroupedAlarms.name;

    constructor(props) {
        super(props);
        this.state = {
            loggedUser: null,
            isAdminUser: false,
            loading: true,
            users: [],
            user: -1,
            metodosEnvio: [],
            metodoEnvio: -1,
            alarmsConfig: [],
            alarmConfig: -1,
            fechaDesdeValue: getFechaDesde(-1),
            fechaHastaValue: getFechaHasta(0),
            lastOrderField: "creadoUTC",
            lastOrderDirection: "DESC",
            events: [],
            event: -1,
            valorDesde: 0,
            valorHasta: 600,
        };
    }

    componentDidMount() {
        this.populateData();
    }
    
    populateData = async () => {
        const loggedUser = getCurrentSession();
        const isUserAdmin = IsUserAdmin();

        let user = -1;
        let users = [];
        if (isUserAdmin) {
            users = await getUsers(loggedUser.empresa.id);
            users.push({ id: -1, nombre: "Todos" });
        }
        else {
            user = loggedUser.id;
            users.push({ id: loggedUser.id, nombre: loggedUser.nombre });
        }

        const metodosEnvioRaw = await getMetodosEnvio();
        const metodosEnvio = metodosEnvioRaw.map(str => ({ id: str, nombre: str }));
        metodosEnvio.push({ id: -1, nombre: "Todos" });

        const alarmConfigRaw = await getGroupedAlarmConfigs(loggedUser.empresa.id);
        const alarmConfig = alarmConfigRaw.map(alarmCfg => ({ id: alarmCfg.id, nombre: alarmCfg.nombre, valor: alarmCfg.valor }));
        alarmConfig.push({ id: -1, nombre: "Todas", valor: 0 });

        const events = await getEvents(loggedUser.empresa.id);
        events.push({ id: -1, nombre: "Todos" });

        this.setState({
            loggedUser: loggedUser,
            isAdminUser: isUserAdmin,
            user: user,
            users: users,
            alarmsConfig: alarmConfig,
            metodosEnvio: metodosEnvio,
            loading: false,
            reportData: [],
            events: events,
        });
    }

    isReportVisible = () => {
        return (this.state.reportData === null || this.state.reportData === undefined || this.state.reportData.length === 0);
    }
    
    processNewOrder = (clickedItem) => {
        let orderDirection = this.state.lastOrderDirection;
        if (this.state.lastOrderField === clickedItem && orderDirection === 'DESC') {
            orderDirection = "ASC";
        } else {
            orderDirection = "DESC";
        }

        this.setState({
            lastOrderField: clickedItem,
            lastOrderDirection: orderDirection,
            reportData: orderReportData(this.state.reportData, clickedItem, orderDirection)
        });
    }

    getReportData = async (loggedUser) => {
        const response = await fetch(`Main/GetAlarmsGroupedRepo/${loggedUser.empresa.id}/${this.state.user}?desde=${this.state.fechaDesdeValue}&hasta=${this.state.fechaHastaValue}`);
        if (response.ok) {
            const all_data = await response.json();

            let data = all_data;

            if (String(this.state.metodoEnvio) !== "-1") {
                data = data
                    .filter((entry) => {
                        return String(entry.servicio).toLowerCase() === String(this.state.metodoEnvio).toLowerCase();
                    });
            }

            if (String(this.state.alarmConfig) !== "-1") {
                data = data
                    .filter((entry) => {
                        return String(entry.idConfiguracionAlarmas).toLowerCase() === String(this.state.alarmConfig).toLowerCase();
                    });
            }

            data = orderReportData(data, "creadoUTC", "DESC");

            data = data.map((item, index) => (
                {
                    id: item.id,
                    creadoUTC: formatDateToCustomString(new Date(item.creadoUTC), 'dd/MM/yyyy HH:mm'),
                    enviadoUTC: formatDateToCustomString(new Date(item.enviadoUTC), 'dd/MM/yyyy HH:mm'),
                    idExterno: getStringFromArray(item.idExternos, ', ').toUpperCase(),
                    idUsuario: getUserFromId(this.state.users, item.idUsuario),
                    destino: `${item.servicio} - ${item.servicioDestino}`,
                    idConfiguracionAlarmas: getConfigFromId(this.state.alarmsConfig, item.idConfiguracionAlarmas),
                    idColectorData: item.idColectorData,
                    mensaje: item.mensaje,
                }
            ));

            return data;
        }
        return [];
    }

    buscar = async () => {
        if (this.state.valorDesde === null || this.state.valorDesde === "") {
            this.setAlertMessage('Debe completar el campo "Valor Desde"');
            return false;
        }
        if (isNaN(this.state.valorDesde)) {
            this.setAlertMessage('El campo "Valor Desde" debe ser numerico');
            return false;
        }

        if (this.state.valorHasta === null || this.state.valorHasta === "") {
            this.setAlertMessage('Debe completar el campo "Valor Hasta"');
            return false;
        }
        if (isNaN(this.state.valorHasta)) {
            this.setAlertMessage('El campo "Valor Desde" debe ser Hasta');
            return false;
        }

        const diasPermitidos = 30;
        if (!getDateTimeDifferenceInMinutes(this.state.fechaDesdeValue, this.state.fechaHastaValue, diasPermitidos)) {
            this.setAlertMessage(`El rango de fechas no puede superar los ${diasPermitidos} dias`);
            return;
        }

        this.setState({
            loading: true,
            reportData: [],
            lastOrderField: "creadoUTC",
            lastOrderDirection: "DESC"
        });

        let reportData = await this.getReportData(this.state.loggedUser);
        reportData = reportData.map((item, index) => (
            {
                id: item.id,
                creadoUTC: item.creadoUTC,
                enviadoUTC: item.enviadoUTC,
                idExterno: item.idExterno,
                idUsuario: item.idUsuario,
                destino: item.destino,
                idConfiguracionAlarmas: item.idConfiguracionAlarmas,
                idColectorData: item.idColectorData,
                mensaje: item.mensaje,

                evento: this.parseItemMessage(item).evento.toUpperCase(),
                valor: this.parseItemMessage(item).valor,
                funcion: capitalizeFirst(this.parseItemMessage(item).funcion),
                seccionEvaluada: this.parseItemMessage(item).analisis,
                maximoPermitido: this.parseItemMessage(item).maximo,
            }
        ));

        reportData = reportData
            .filter((entry) => {
                return Number(convertHoursToSeconds(entry.valor)) >= Number(this.state.valorDesde) && Number(convertHoursToSeconds(entry.valor)) <= Number(this.state.valorHasta);
            });

        if (String(this.state.event) !== "-1") {
            reportData = reportData
                .filter((entry) => {
                    return String(entry.evento).toLowerCase() === String(this.state.event).toLowerCase();
                });
        }

        if (reportData === null || reportData === undefined || reportData.length === 0) {
            this.setAlertMessage("No hay datos para visualizar");
        }

        this.setState({
            reportData: reportData,
            loading: false
        });
    }

    cerrar = async () => {
        this.setState({
            reportData: []
        });
    }
    
    parseItemMessage = (item) => {
        let parsedValues = {
            evento: 'N/A',
            valor: 'N/A',
            funcion: 'N/A',
            analisis: 'N/A',
            maximo: 'N/A',
        };
        if (item !== null && item !== undefined) {
            const mensaje = item.mensaje;

            const analisis = String(mensaje).split("\n")[1].split("'")[1] ?? "'N/A";
            const funcion = String(mensaje).split("\n")[1].split("'")[2].split(" se obtuvo ")[1].split(" ")[1] ?? "'N/A";
            const valor = String(mensaje).split("\n")[2].split("'")[1] ?? "'N/A";
            const evento = String(mensaje).split("\n")[2].split("'")[3] ?? "'N/A";
            const maximo = String(mensaje).split("\n")[2].split("'")[5] ?? "'N/A";

            parsedValues.evento = evento;
            parsedValues.valor = valor;
            parsedValues.funcion = funcion;
            parsedValues.analisis = analisis;
            parsedValues.maximo = maximo;
        }
        return parsedValues;
    }

    getMainPage = () => {
        return (
            <>
                {this.getMessageArea()}
                <div className="reportHeader" style={{ display: this.isReportVisible() ? "inherit" : "none" }}>
                    <h4>Reporte Alarmas Agrupadas</h4>
                    <div className="floatingDiv" style={{ width: "calc(100% / 2)" }}>
                        <label htmlFor="CboUsuarios">Usuarios: </label>
                        <select id="CboUsuarios" defaultValue={this.state.user} onChange={(e) => this.setState({ user: e.target.value })}>
                            {this.state.users.map(user =>
                                <option key={user.id} value={user.id}>{user.nombre}</option>
                            )}
                        </select>
                    </div>
                    <div className="floatingDiv" style={{ width: "calc(100% / 2)" }}>
                        <label htmlFor="CboMetodosEnvio">Destinos: </label>
                        <select id="CboMetodosEnvio" defaultValue={this.state.metodoEnvio} onChange={(e) => this.setState({ metodoEnvio: e.target.value })}>
                            {this.state.metodosEnvio.map(metodoEnvio =>
                                <option key={metodoEnvio.id} value={metodoEnvio.id}>{metodoEnvio.nombre}</option>
                            )}
                        </select>
                    </div>
                    <div className="floatingDiv" style={{ width: "calc(100% / 2)" }}>
                        <label htmlFor="CboConfiguraciones">Configs: </label>
                        <select id="CboConfiguraciones" defaultValue={this.state.alarmConfig} onChange={(e) => this.setState({ alarmConfig: e.target.value })}>
                            {this.state.alarmsConfig.map(alarmConfig =>
                                <option key={alarmConfig.id} value={alarmConfig.id}>{alarmConfig.nombre}</option>
                            )}
                        </select>
                    </div>

                    <div className="floatingDiv" style={{ width: "calc(100% / 2)" }}>
                        <label htmlFor="CboEvents">Eventos: </label>
                        <select id="CboEvents" defaultValue={this.state.event} onChange={(e) => this.setState({ event: e.target.value })}>
                            {this.state.events.map(event =>
                                <option key={event.id} value={event.id}>{event.nombre}</option>
                            )}
                        </select>
                    </div>

                    <div className="floatingDiv" style={{ width: "calc(100% / 2)" }}>
                        <label>Valor Desde: </label>
                        <input type="number" minLength={1} maxLength={9} className="single-input" style={{ textAlign: "center" }} value={this.state.valorDesde} onChange={(e) => this.setState({ valorDesde: e.target.value })}></input>
                        <br />
                        <label style={{ fontSize: "10px", color: "var(--bs-secondary)" }}>{secondsToHours(this.state.valorDesde)}</label>
                    </div>
                    <div className="floatingDiv" style={{ width: "calc(100% / 2)" }}>
                        <label>Valor Hasta: </label>
                        <input type="number" minLength={1} maxLength={9} className="single-input" style={{ textAlign: "center" }} value={this.state.valorHasta} onChange={(e) => this.setState({ valorHasta: e.target.value })}></input>
                        <br />
                        <label style={{ fontSize: "10px", color: "var(--bs-secondary)" }}>{secondsToHours(this.state.valorHasta)}</label>
                    </div>

                    <div className="floatingDiv" style={{ width: "calc(100% / 2)" }}>
                        <label>Fecha Desde: </label>
                        <input type="datetime-local" id="DTFrom" value={this.state.fechaDesdeValue} min={getFechaMinima()} max={getFechaMaxima()} onChange={(e) => this.setState({ fechaDesdeValue: e.target.value })} />

                    </div>
                    <div className="floatingDiv" style={{ width: "calc(100% / 2)" }}>
                        <label>Fecha Desde: </label>
                        <input type="datetime-local" id="DTTo" value={this.state.fechaHastaValue} min={getFechaMinima()} max={getFechaMaxima()} onChange={(e) => this.setState({ fechaHastaValue: e.target.value })} />
                    </div>

                    <p style={{ width: "100%" }}>
                        <button onClick={(e) => this.buscar()}>Buscar</button>
                    </p>
                </div>

                <div className="reportHeader" style={{ display: this.isReportVisible() ? "none" : "inherit" }}>
                    <h4>Reporte Alarmas Agrupadas</h4>
                    <p>
                        <button style={{ backgroundColor: "var(--bs-success)", border: "1px solid var(--bs-success)" }} onClick={(e) => exportToExcel('MainTbl', 'Reporte_Alarmas')}>Exportar</button>
                        <button onClick={(e) => this.cerrar()}>Cerrar</button>
                    </p>
                </div>

                <div style={{ textAlign: "center", display: this.isReportVisible() ? "none" : "inherit" }}>
                    <table id="MainTbl" className='table table-striped mini_report_table'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th style={{ cursor: "pointer" }} onClick={(e) => this.processNewOrder('creadoUTC')}>Ingestado {getRowIcon(this.state.lastOrderField, this.state.lastOrderDirection,'creadoUTC')}</th>
                                <th style={{ cursor: "pointer" }} onClick={(e) => this.processNewOrder('enviadoUTC')}>Enviado {getRowIcon(this.state.lastOrderField, this.state.lastOrderDirection,'enviadoUTC')}</th>
                                <th style={{ cursor: "pointer" }} onClick={(e) => this.processNewOrder('funcion')}>Funcion {getRowIcon(this.state.lastOrderField, this.state.lastOrderDirection,'funcion')}</th>
                                <th style={{ cursor: "pointer" }} onClick={(e) => this.processNewOrder('evento')}>Evento {getRowIcon(this.state.lastOrderField, this.state.lastOrderDirection,'evento')}</th>
                                <th style={{ cursor: "pointer" }} onClick={(e) => this.processNewOrder('valor')}>Valor {getRowIcon(this.state.lastOrderField, this.state.lastOrderDirection,'valor')}</th>
                                <th style={{ cursor: "pointer", width: "20%", maxWidth: "20%" }} onClick={(e) => this.processNewOrder('idExterno')}>Ids Externos {getRowIcon(this.state.lastOrderField, this.state.lastOrderDirection,'idExterno')}</th>
                                <th style={{ cursor: "pointer" }} onClick={(e) => this.processNewOrder('idUsuario')}>Usuario {getRowIcon(this.state.lastOrderField, this.state.lastOrderDirection,'idUsuario')}</th>
                                <th style={{ cursor: "pointer" }} onClick={(e) => this.processNewOrder('destino')}>Destino {getRowIcon(this.state.lastOrderField, this.state.lastOrderDirection,'destino')}</th>
                                <th style={{ cursor: "pointer" }} onClick={(e) => this.processNewOrder('idConfiguracionAlarmas')}>Configuracion {getRowIcon(this.state.lastOrderField, this.state.lastOrderDirection,'idConfiguracionAlarmas')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.reportData.map((data, index) =>
                                <tr key={data.id}>
                                    <td>{index + 1}</td>
                                    <td>{data.creadoUTC}</td>
                                    <td>{data.enviadoUTC}</td>
                                    <td>{data.funcion}</td>
                                    <td>{data.evento}</td>
                                    <td>{data.valor}</td>
                                    <td>{data.idExterno}</td>
                                    <td>{data.idUsuario}</td>
                                    <td>{data.destino}</td>
                                    <td>{data.idConfiguracionAlarmas}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </>
        );
    };

    render() {
        return this.state.loading ? this.getRender() : this.state.isAdminUser ? this.getMainPage() : this.getUnauthorizedPage();
    }
}