import React from 'react';
import { Shared } from '../components/Shared';
import { capitalizeFirst, getEmpresaDateTime, getTokenExpiryDateTime, getCurrentSession, renewSession, updateCurrentSession, getValidationToken, usuarioObj, mailObj, codigoVerificacionObj, claveObj, maskString, codAreaObj, celularObj, codigoVerificacionMailObj } from '../utils';
import { BsFillGearFill, BsStars, BsPhoneVibrateFill, BsFillEnvelopeAtFill } from "react-icons/bs";

export class Profile extends Shared {
    constructor(props) {
        super(props);

        this.state = {
            operacion: '',
            empresa: '',
            usuario: '',
            clave: '',
            publicKey: '',
            mail: '',
            codigoArea: '',
            celular: '',
            gmt: '',
            currentDate: null,
            idSesion: '',
            validaHastaUTC: '',
            updateCode: '',
            updatedUsername: '',
            updatedMail: '',
            updatedPassword: '',
            updatedPasswordVerification: '',
            updatedAreaCode: '',
            updatedMobile: '',
            hasRequestedCode: false,
        };
    }

    componentDidMount() {
        this.populateData();
    }

    populateData = async () => {
        const loggedUser = getCurrentSession();
        this.clearOps('');
        this.setState({
            loading: false,
            alertMessage: '',
            successMessage: '',
            empresa: capitalizeFirst(loggedUser.empresa.nombre),
            usuario: capitalizeFirst(loggedUser.nombre),
            clave: String(loggedUser.clave),
            publicKey: String(loggedUser.publicKey),
            mail: String(loggedUser.mail),
            codigoArea: String(loggedUser.codigoArea),
            celular: String(loggedUser.celular),
            gmt: String(loggedUser.empresa.gmt),
            currentDate: getEmpresaDateTime(loggedUser),
            idSesion: String(loggedUser.idSesion),
            validaHastaUTC: getTokenExpiryDateTime(loggedUser),
        });
    }

    clearOps = (operacion) => {
        this.setState({
            operacion: operacion,
            updateCode: '',
            updatedUsername: '',
            updatedMail: '',
            updatedPassword: '',
            updatedPasswordVerification: '',
            updatedAreaCode: '',
            updatedMobile: '',
            hasRequestedCode: false,
        });
    }

    refreshServerDateTime = async () => {
        this.setState({
            currentDate: getEmpresaDateTime()
        });
    }

    renewMySession = async () => {
        this.setState({ loading: true });
        const response = await renewSession();
        await this.populateData();
        if (!response) {
            this.setAlertMessage("Error al refrescar la sesion");
        }
        else {
            this.setSuccessMessage("Sesion renovada correctamente!")
        }
    }

    updateUsername = async (e) => {
        e.preventDefault();
        if (this.state.updatedUsername === this.state.nombre) {
            this.setAlertMessage(`El usuario debe ser diferente al actual.`);
            return false;
        }
        if (!this.checkField(this.state.updatedUsername, usuarioObj)) {
            return false;
        }
        if (!this.checkField(this.state.updateCode, codigoVerificacionObj)) {
            return false;
        }

        this.setState({ loading: true });
        const loggedUser = getCurrentSession();
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(getValidationToken(this.state.updateCode))
        };
        const response = await fetch(`Login/UpdateUsername/${encodeURIComponent(loggedUser.empresa.nombre)}/${loggedUser.id}?username=${encodeURIComponent(this.state.updatedUsername)}`, requestOptions);
        this.setState({ loading: false });

        if (response.ok) {
            loggedUser.nombre = this.state.updatedUsername;
            updateCurrentSession(loggedUser);
            this.setState({ usuario: this.state.updatedUsername });
        }
        await this.setInitialPage();

        if (response.ok) {
            const responseText = await response.text();
            this.setSuccessMessage(responseText)
        }
        else {
            const responseObj = await response.json();
            const responseText = responseObj.detail ?? 'No pudimos actualizar el usuario';
            this.setAlertMessage(responseText);
        }
    }

    updatePassword = async (e) => {
        e.preventDefault();
        if (this.state.updatedPassword === this.state.publicKey) {
            this.setAlertMessage(`La clave debe ser diferente a la actual.`);
            return false;
        }
        if (!this.checkField(this.state.updatedPassword, claveObj)) {
            return false;
        }
        if (this.state.updatedPassword !== this.state.updatedPasswordVerification) {
            this.setAlertMessage(`Las claves no coinciden.`);
            return false;
        }
        if (!this.checkField(this.state.updateCode, codigoVerificacionObj)) {
            return false;
        }

        this.setState({ loading: true });
        const loggedUser = getCurrentSession();
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(getValidationToken(this.state.updateCode))
        };
        const response = await fetch(`Login/UpdatePassword/${loggedUser.idEmpresa}/${loggedUser.id}?password=${btoa(this.state.updatedPassword)}`, requestOptions);
        this.setState({ loading: false });

        if (response.ok) {
            loggedUser.clave = maskString(this.state.updatedPassword);
            loggedUser.publicKey = this.state.updatedPassword;
            updateCurrentSession(loggedUser);
            this.setState({ clave: maskString(this.state.updatedPassword), publicKey: this.state.updatedPassword });
        }
        await this.setInitialPage();

        if (response.ok) {
            const responseText = await response.text();
            this.setSuccessMessage(responseText)
        }
        else {
            const responseObj = await response.json();
            const responseText = responseObj.detail ?? 'No pudimos actualizar la clave';
            this.setAlertMessage(responseText);
        }
    }

    requestNewMailCode = async () => {
        if (this.state.updatedMail === this.state.mail) {
            this.setAlertMessage(`El mail debe ser diferente al actual.`);
            return false;
        }
        if (!this.checkField(this.state.updatedMail, mailObj)) {
            return false;
        }

        this.setState({ loading: true });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(getValidationToken(this.state.updateCode))
        };
        const response = await fetch(`Login/SendNewCode?email=${encodeURIComponent(this.state.updatedMail)}`, requestOptions);
        this.setState({ loading: false });

        if (response.ok) {
            this.setSuccessMessage('Se ha enviado el codigo.')
            this.setState({ hasRequestedCode: true });
        }
        else {
            this.setAlertMessage('No pudimos enviar el codigo.');
        }
    }

    updateMail = async (e) => {
        e.preventDefault();
        if (!this.checkField(this.state.updateCode, codigoVerificacionMailObj)) {
            return false;
        }

        this.setState({ loading: true });
        const loggedUser = getCurrentSession();
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(getValidationToken(this.state.updateCode))
        };
        const response = await fetch(`Login/UpdateMail/${loggedUser.idEmpresa}/${loggedUser.id}?email=${encodeURIComponent(this.state.updatedMail)}`, requestOptions);
        this.setState({ loading: false });

        if (response.ok) {
            loggedUser.mail = this.state.updatedMail;
            updateCurrentSession(loggedUser);
            this.setState({ mail: this.state.updatedMail });
        }
        await this.setInitialPage();

        if (response.ok) {
            const responseText = await response.text();
            this.setSuccessMessage(responseText)
        }
        else {
            const responseObj = await response.json();
            const responseText = responseObj.detail ?? 'No pudimos actualizar el mail';
            this.setAlertMessage(responseText);
        }
    }

    requestNewSMSCode = async () => {
        if (`${this.state.updatedAreaCode}-${this.state.updatedMobile}` === `${this.state.codigoArea}-${this.state.celular}`) {
            this.setAlertMessage(`El celular debe ser diferente al actual.`);
            return false;
        }
        if (!this.checkField(this.state.updatedAreaCode, codAreaObj)) {
            return false;
        }
        if (!this.checkField(this.state.updatedMobile, celularObj)) {
            return false;
        }

        this.setState({ loading: true });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(getValidationToken(this.state.updateCode))
        };
        const response = await fetch(`Login/SendNewCode/${this.state.updatedAreaCode}/${this.state.updatedMobile}`, requestOptions);
        this.setState({ loading: false });

        if (response.ok) {
            this.setSuccessMessage('Se ha enviado el codigo.')
            this.setState({ hasRequestedCode: true });
        }
        else {
            this.setAlertMessage('No pudimos enviar el codigo.');
        }
    }

    updateMobile = async (e) => {
        e.preventDefault();
        if (!this.checkField(this.state.updateCode, codigoVerificacionObj)) {
            return false;
        }

        this.setState({ loading: true });
        const loggedUser = getCurrentSession();
        const preToken = { code: this.state.updateCode, gmt: loggedUser.empresa.gmt, number: this.state.updatedMobile };
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(preToken)
        };
        const response = await fetch(`Login/UpdateMobile/${loggedUser.idEmpresa}/${loggedUser.id}?areaCode=${this.state.updatedAreaCode}&mobileNumber=${this.state.updatedMobile}`, requestOptions);
        this.setState({ loading: false });

        if (response.ok) {
            loggedUser.codigoArea = this.state.updatedAreaCode;
            loggedUser.celular = this.state.updatedMobile;
            updateCurrentSession(loggedUser);
            this.setState({ codigoArea: this.state.updatedAreaCode, celular: this.state.updatedMobile });
        }
        await this.setInitialPage();

        if (response.ok) {
            const responseText = await response.text();
            this.setSuccessMessage(responseText)
        }
        else {
            const responseObj = await response.json();
            const responseText = responseObj.detail ?? 'No pudimos actualizar el celular';
            this.setAlertMessage(responseText);
        }
    }

    requestSMSCode = async () => {
        this.setState({ loading: true });

        const loggedUser = getCurrentSession();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify()
        };
        const response = await fetch(`Login/Recover/${encodeURIComponent(loggedUser.empresa.nombre)}/${encodeURIComponent(loggedUser.nombre)}?Metodo=SMS`, requestOptions);
        this.setState({ loading: false });

        if (response.ok) {
            this.setSuccessMessage('Se ha enviado el codigo.')
        }
        else {
            this.setAlertMessage('No pudimos enviar el codigo.');
        }
    }

    requestMailCode = async () => {
        this.setState({ loading: true });

        const loggedUser = getCurrentSession();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify()
        };
        const response = await fetch(`Login/Recover/${encodeURIComponent(loggedUser.empresa.nombre)}/${encodeURIComponent(loggedUser.nombre)}?Metodo=Mail`, requestOptions);
        this.setState({ loading: false });

        if (response.ok) {
            this.setSuccessMessage('Se ha enviado el codigo.')
        }
        else {
            this.setAlertMessage('No pudimos enviar el codigo.');
        }
    }

    setInitialPage = async () => {
        this.clearOps('');
    }

    setUsernameUpdate = async () => {
        this.clearOps('username');
    }

    setPasswordUpdate = async () => {
        this.clearOps('password');
    }

    setMailUpdate = async () => {
        this.clearOps('mail');
    }

    setMobilelUpdate = async () => {
        this.clearOps('mobile');
    }

    getLandingPage = () => {
        switch (this.state.operacion) {
            case 'username':
                return this.getUsernameUpdate();
            case 'password':
                return this.getPasswordUpdate();
            case 'mail':
                return this.getMailUpdate();
            case 'mobile':
                return this.getMobileUpdate();
            default:
                return this.getDisplayPage();
        }
    };

    getContainerTitle = (title) => {
        return (
            <div>
                <h1>{title}</h1>
                <br />
            </div>
        );
    }

    getSessionInformation = () => {
        return (
            <div style={{ textAlign: "center", verticalAlign: "middle", margin: "25px auto 0px auto", backgroundColor: "var(--bs-gray-200)", border: "2px solid var(--blueColor)", borderRadius: "5px", width: "fit-content", padding: "10px" }}>
                <p style={{ textAlign: "center", fontSize: "0.85rem" }}><b>Id Sesion: </b> {this.state.idSesion}</p>
                <p style={{ textAlign: "center", fontSize: "0.85rem" }}><b>Valido hasta: </b> {this.state.validaHastaUTC}{<BsStars onClick={this.renewMySession} className="update-input-data-btn" style={{ fontSize: "0.85rem" }} alt="Renovar sesion" title="Renovar sesion"></BsStars>}</p>
            </div>
        );
    }

    getOperacionesButtons = () => {
        return (
            <div>
                <button type="submit">Actualizar</button>
                <br />
                <button type="button" onClick={this.setInitialPage} style={{ backgroundColor: "var(--bs-danger)", borderColor: "var(--bs-danger)" }}>Cancelar</button>
            </div>
        );
    }

    getCodeInput = () => {
        return (
            <input type="number" minLength={claveObj.minLength} maxLength={claveObj.maxLength} className="single-input" style={{ textAlign: "center" }} placeholder="Codigo de verificacion" onChange={(e) => this.setState({ updateCode: e.target.value })}></input>
        )
    };

    getCodeRequester = () => {
        return (
            <div style={{ textAlign: "center", verticalAlign: "middle", margin: "15px auto 15px auto", width: "fit-content", padding: "0px" }}>
                <p style={{ textAlign: "center" }} ><b style={{ fontSize: "0.8rem" }}>Solicitar Codigo de verificacion: </b></p>
                <p style={{ textAlign: "center" }}>
                    {<BsPhoneVibrateFill style={{ fontSize: "2rem" }} className="update-input-data-btn" alt="SMS" title="SMS" onClick={this.requestSMSCode}></BsPhoneVibrateFill>}
                    {<BsFillEnvelopeAtFill style={{ fontSize: "2rem" }} className="update-input-data-btn" alt="Mail" title="Mail" onClick={this.requestMailCode}></BsFillEnvelopeAtFill>}
                </p>
            </div>
        )
    };

    getDisplayPage = () => {
        return (
            <div className="mainContainer">
                {this.getContainerTitle('Perfil')}

                <p><b>Empresa: </b> {this.state.empresa}</p>
                <p><b>GMT: </b> {this.state.gmt} ({this.state.currentDate}){<BsStars onClick={this.refreshServerDateTime} className="update-input-data-btn" alt="Refrescar horario" title="Refrescar horario"></BsStars>}</p>
                <p><b>Usuario: </b> {this.state.usuario}{<BsFillGearFill onClick={this.setUsernameUpdate} className="update-input-data-btn" alt="Cambiar nombre usuario" title="Cambiar nombre usuario"></BsFillGearFill>}</p>
                <p><b>Clave: </b> {this.state.clave}{<BsFillGearFill onClick={this.setPasswordUpdate} className="update-input-data-btn" alt="Cambiar clave" title="Cambiar clave"></BsFillGearFill>}</p>
                <p><b>Mail: </b> {this.state.mail}{<BsFillGearFill onClick={this.setMailUpdate} className="update-input-data-btn" alt="Cambiar mail" title="Cambiar mail"></BsFillGearFill>}</p>
                <p><b>Celular: </b> +[{this.state.codigoArea}] {this.state.celular}{<BsFillGearFill onClick={this.setMobilelUpdate} className="update-input-data-btn" alt="Cambiar celular" title="Cambiar celular"></BsFillGearFill>}</p>

                {this.getSessionInformation()}
                {this.getCodeRequester()}
                {this.getMessageArea()}
            </div>
        );
    };

    getUsernameUpdate = () => {
        return (
            <form onSubmit={this.updateUsername}>
                <div className="mainContainer">
                    {this.getContainerTitle('Actualizar Usuario')}

                    <input type="text" minLength={usuarioObj.minLength} maxLength={usuarioObj.maxLength} className="single-input" style={{ textAlign: "center" }} placeholder={this.state.usuario} onChange={(e) => this.setState({ updatedUsername: capitalizeFirst(e.target.value) })}></input>
                    <br />

                    {this.getCodeInput()}
                    {this.getOperacionesButtons()}
                    {this.getCodeRequester()}
                    {this.getMessageArea()}
                </div>
            </form>
        );
    };

    getPasswordUpdate = () => {
        return (
            <form onSubmit={this.updatePassword}>
                <div className="mainContainer">
                    {this.getContainerTitle('Actualizar Clave')}

                    <input type="password" minLength={claveObj.minLength} maxLength={claveObj.maxLength} className="single-input" style={{ textAlign: "center" }} placeholder="Ingrese la nueva clave" onChange={(e) => this.setState({ updatedPassword: e.target.value })}></input>
                    <br />
                    <input type="password" minLength={claveObj.minLength} maxLength={claveObj.maxLength} className="single-input" style={{ textAlign: "center" }} placeholder="Confirme la clave" onChange={(e) => this.setState({ updatedPasswordVerification: e.target.value })}></input>
                    <br />

                    {this.getCodeInput()}
                    {this.getOperacionesButtons()}
                    {this.getCodeRequester()}
                    {this.getMessageArea()}
                </div>
            </form>
        );
    };

    getMailUpdatePre = () => {
        return (
            <div>
                <input type="email" minLength={mailObj.minLength} maxLength={mailObj.maxLength} className="single-input" style={{ textAlign: "center" }} placeholder={this.state.mail} onChange={(e) => this.setState({ updatedMail: e.target.value })}></input>
                <br />
                <button type="button" onClick={this.requestNewMailCode} style={{ backgroundColor: "var(--bs-success)", borderColor: "var(--bs-success)" }}>Solicitar nuevo codigo</button>
                <br />
                <button type="button" onClick={this.setInitialPage} style={{ backgroundColor: "var(--bs-danger)", borderColor: "var(--bs-danger)" }}>Cancelar</button>
            </div>
        );
    };

    getMailUpdatePost = () => {
        return (
            <div>
                <p><b>Mail: </b> {this.state.updatedMail}</p>
                {this.getCodeInput()}
                {this.getOperacionesButtons()}
            </div>
        );
    };

    getMailUpdate = () => {
        return (
            <form onSubmit={this.updateMail}>
                <div className="mainContainer">
                    {this.getContainerTitle('Actualizar Mail')}

                    {!this.state.hasRequestedCode ? this.getMailUpdatePre() : this.getMailUpdatePost()}
                    {this.getMessageArea()}
                </div>
            </form>
        );
    };

    getMobileUpdatePre = () => {
        return (
            <div>
                <input type="number" minLength={codAreaObj.minLength} maxLength={codAreaObj.maxLength} className="single-input" style={{ textAlign: "center" }} placeholder={this.state.codigoArea} onChange={(e) => this.setState({ updatedAreaCode: e.target.value })}></input>
                <br />
                <input type="number" minLength={celularObj.minLength} maxLength={celularObj.maxLength} className="single-input" style={{ textAlign: "center" }} placeholder={this.state.celular} onChange={(e) => this.setState({ updatedMobile: e.target.value })}></input>
                <br />
                <button type="button" onClick={this.requestNewSMSCode} style={{ backgroundColor: "var(--bs-success)", borderColor: "var(--bs-success)" }}>Solicitar nuevo codigo</button>
                <br />
                <button type="button" onClick={this.setInitialPage} style={{ backgroundColor: "var(--bs-danger)", borderColor: "var(--bs-danger)" }}>Cancelar</button>
            </div>
        );
    };

    getMobileUpdatePost = () => {
        return (
            <div>
                <p><b>Codigo de Area: </b> {this.state.updatedAreaCode}</p>
                <p><b>Celular: </b> {this.state.updatedMobile}</p>
                {this.getCodeInput()}
                {this.getOperacionesButtons()}
            </div>
        );
    };

    getMobileUpdate = () => {
        return (
            <form onSubmit={this.updateMobile}>
                <div className="mainContainer">
                    {this.getContainerTitle('Actualizar Celular')}

                    {!this.state.hasRequestedCode ? this.getMobileUpdatePre() : this.getMobileUpdatePost()}
                    {this.getMessageArea()}
                </div>
            </form>
        );
    };

    render() {
        return this.state.loading ? this.getRender() : this.getLandingPage();
    }
}
