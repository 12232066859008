import React, { useState, useEffect, useCallback } from 'react';
import $ from 'jquery';

function SimpleAlertComponent({ message, onClose }) {
    const [visible, setVisible] = useState(false);

    const closeMe = useCallback(() => {
        setVisible(false);
        $("#alertBox").fadeOut("slow");
        onClose();
    }, [onClose]);

    useEffect(() => {
        if (message) {
            setVisible(true);
            $("#alertBox").fadeIn("slow");
            const alertTimeout = setTimeout(() => {
                closeMe();
            }, 3000);
            return () => clearTimeout(alertTimeout);
        }
    }, [message, closeMe]);

    return (
        <div id="alertBox" className={`myalert alert alert-warning alert-dismissible${visible ? '' : ' d-none'}`} role="alert">
            <strong>Ups!</strong> {message}
            <button type="button" className="btn-close" onClick={() => closeMe()}></button>
        </div>
    );
}

function SimpleSuccessComponent({ message, onClose }) {
    const [visible, setVisible] = useState(false);

    const closeMe = useCallback(() => {
        setVisible(false);
        $("#successBox").fadeOut("slow");
        onClose();
    }, [onClose]);

    useEffect(() => {
        if (message) {
            setVisible(true);
            $("#successBox").fadeIn("slow");
            const alertTimeout = setTimeout(() => {
                closeMe();
            }, 3000);
            return () => clearTimeout(alertTimeout);
        }
    }, [message, closeMe]);

    return (
        <div id="successBox" className={`myalert alert alert-success alert-dismissible${visible ? '' : ' d-none'}`} role="alert">
            {message}
            <button type="button" className="btn-close" onClick={() => closeMe()}></button>
        </div>
    );
}

function SimpleConfirmationComponent({ title, message, onOk, onCancel, btnOkText, btnCancelText, onClose }) {
    const [visible, setVisible] = useState(false);

    const clearAndClose = () => {
        setVisible(false);
        $("#confirmationBox").fadeOut("slow");
    };

    const acceptMe = useCallback(() => {
        clearAndClose();
        onOk();
    }, [onOk, clearAndClose]);

    const cancelMe = useCallback(() => {
        clearAndClose();
        onCancel();
    }, [onCancel, clearAndClose]);

    const closeMe = useCallback(() => {
        clearAndClose();
        onClose();
    }, [onClose, clearAndClose]);

    useEffect(() => {
        if (message) {
            setVisible(true);
            $("#confirmationBox").fadeIn("slow");
            return;
        }
    }, [message, closeMe]);

    return (
        <div id="confirmationBox" className={`myalert myconfirm${visible ? '' : ' d-none'}`} role="alert">
            <div className="floatingConfirmationBox">
                <div className="floatingConfirmationBoxTitle">
                    <b>{title}</b>
                    <button type="button" className="btn-close btn-close-white" onClick={() => closeMe()}></button>
                </div>
                <div className="floatingConfirmationBoxMessage">
                    <b>{message}</b>
                </div>
                <div className="floatingConfirmationBoxFooter">
                    <button type="button" className="confirm-btn" onClick={() => acceptMe()}>{btnOkText}</button>
                    <button type="button" className="confirm-btn" onClick={() => cancelMe()}>{btnCancelText}</button>
                </div>
            </div>
        </div>
    );
}

export { SimpleAlertComponent, SimpleSuccessComponent, SimpleConfirmationComponent };