import { React } from 'react';
import { Shared } from '../../components/Shared';
import { IsUserAdmin, getCurrentSession, usuarioObj, mailObj, codAreaObj, celularObj, validateField, getReservedUsername, getRequiredIcon, getJerarquias } from '../../utils';
import { BsFillPlusCircleFill, BsFillCheckCircleFill, BsFillXCircleFill, BsTrashFill, BsPhoneVibrateFill, BsFillEnvelopeAtFill } from "react-icons/bs";
import { SimpleConfirmationComponent } from '../../components/Alerts';

export class ConfigUsers extends Shared {
    static displayName = ConfigUsers.name;

    constructor(props) {
        super(props);
        this.state = {
            loggedUser: null,
            isAdminUser: false,
            users: null,
            jerarquias: null,
            selectedUser_nombre: null,
            selectedUser_mail: null,
            selectedUser_codigo_area: null,
            selectedUser_celular: null,
            selectedUser_jerarquia: null,
            selectedUser_activo: null,
            selectedUser_id: null,
            confirmationTitle: '',
            confirmationMessage: '',
            confirmationMethod: '',
            loading: true
        };
    }

    componentDidMount() {
        this.populateData();
    }

    clearSelection = async () => {
        this.setState({
            selectedUser_nombre: null,
            selectedUser_mail: null,
            selectedUser_codigo_area: null,
            selectedUser_celular: null,
            selectedUser_jerarquia: null,
            selectedUser_activo: null,
            selectedUser_id: null,
        });
        this.clearConfirmation();
    }

    rowClickHandler = async (selectedUserId) => {
        const selectedUser = this.state.users.filter(item => item.id === selectedUserId);
        if (selectedUser === null || selectedUser === undefined || selectedUser.length === 0) {
            this.setAlertMessage("Error al obtener datos del usuario seleccionado");
        }
        this.setState({
            selectedUser_nombre: selectedUser[0].nombre,
            selectedUser_mail: selectedUser[0].mail,
            selectedUser_codigo_area: selectedUser[0].codigoArea,
            selectedUser_celular: selectedUser[0].celular,
            selectedUser_jerarquia: selectedUser[0].idJerarquia,
            selectedUser_activo: selectedUser[0].activo,
            selectedUser_id: selectedUserId
        });
    }

    addNewUser = async () => {
        this.setState({
            selectedUser_nombre: "",
            selectedUser_mail: "",
            selectedUser_codigo_area: "",
            selectedUser_celular: "",
            selectedUser_jerarquia: "Usuario",
            selectedUser_activo: null,
            selectedUser_id: 0,
        });
    }

    populateData = async () => {
        this.clearSelection();
        const loggedUser = getCurrentSession();
        const jerarquias = await getJerarquias();
        this.setState({
            loggedUser: loggedUser,
            isAdminUser: IsUserAdmin(),
            jerarquias: jerarquias,
            users: await this.getUsers(loggedUser),
            loading: false
        });
    }

    getUsers = async (loggedUser) => {
        const response = await fetch(`Main/GetUsers/${loggedUser.empresa.id}`);
        if (response.ok) {
            const all_users = await response.json();
            const users = all_users.filter(function (entry) {
                return entry.id !== loggedUser.id && !getReservedUsername(entry.nombre);
            })
            .sort(function (a, b) {
                return a.nombre.localeCompare(b.nombre);
            });
            return users;
        }
        return [];
    }

    clearConfirmation = () => {
        this.setState({
            confirmationTitle: '',
            confirmationMessage: '',
            confirmationMethod: ''
        });
    }

    setupConfirmation = (method) => {
        switch (method) {
            case "SMS":
            case "Mail":
                this.setState({
                    confirmationTitle: "Cambio de clave",
                    confirmationMessage: `Desea generar una nueva clave y enviarsela por ${method} al usuario?`,
                    confirmationMethod: method
                });
                break;
            case "Cancelar":
            case "Guardar":
            case "Eliminar":
            case "Crear":
                this.setState({
                    confirmationTitle: method,
                    confirmationMessage: `Seguro que desea ${String(method).toLowerCase()}?`,
                    confirmationMethod: method
                });
                break;
            default:
                this.clearConfirmation();
                break;
        }
    };

    handleOkConfirmation = async (e) => {
        this.clearConfirmation();
        this.setState({
            loading: true
        });

        switch (this.state.confirmationMethod) {
            case "SMS":
            case "Mail":
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify()
                };
                const response = await fetch(`Main/SendNewPassword/${encodeURIComponent(this.state.loggedUser.empresa.nombre)}/${encodeURIComponent(this.state.selectedUser_nombre)}?Metodo=${this.state.confirmationMethod}`, requestOptions);
                this.setState({ loading: false });

                if (response.ok) {
                    this.setSuccessMessage('Se ha enviado la nueva clave correctamente.')
                    this.clearSelection();
                }
                else {
                    this.setAlertMessage('No pudimos enviar la nueva clave.');
                }
                break;
            case "Cancelar":
                this.clearSelection();
                break;
            case "Guardar":
                if (this.validateInputs()) {
                    const updateUser = {
                        nombre: this.state.selectedUser_nombre,
                        clave: null,
                        mail: this.state.selectedUser_mail,
                        codigoArea: this.state.selectedUser_codigo_area,
                        celular: this.state.selectedUser_celular,
                        idJerarquia: this.state.selectedUser_jerarquia,
                        activo: this.state.selectedUser_activo,
                        validado: null
                    };
                    const requestOptions = {
                        method: 'PUT',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(updateUser)
                    };
                    const response = await fetch(`Main/UpdateUserData/${this.state.loggedUser.empresa.id}/${this.state.selectedUser_id}`, requestOptions);
                    this.setState({
                        users: await this.getUsers(this.state.loggedUser),
                        loading: false
                    });

                    if (response.ok) {
                        this.setSuccessMessage('Se ha actualizado el usuario correctamente.')
                        this.clearSelection();
                    }
                    else {
                        this.setAlertMessage('No pudimos actualizar el usuario.');
                    }
                }
                break;
            case "Eliminar":
                const requestOptionsDelete = {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify()
                };
                const responseDelete = await fetch(`Main/DeleteUserData/${this.state.loggedUser.empresa.id}/${this.state.selectedUser_id}`, requestOptionsDelete);
                this.setState({
                    users: await this.getUsers(this.state.loggedUser),
                    loading: false
                });

                if (responseDelete.ok) {
                    this.setSuccessMessage('Se ha eliminado el usuario correctamente.')
                    this.clearSelection();
                }
                else {
                    this.setAlertMessage('No pudimos eliminar el usuario.');
                }
                break;
            case "Crear":
                const createUser = {
                    nombre: this.state.selectedUser_nombre,
                    mail: this.state.selectedUser_mail,
                    codigoArea: this.state.selectedUser_codigo_area,
                    celular: this.state.selectedUser_celular,
                    idJerarquia: this.state.selectedUser_jerarquia
                };
                const requestOptionsCreate = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(createUser)
                };
                const responseCreate = await fetch(`Main/CreateUserData/${this.state.loggedUser.empresa.id}/${this.state.selectedUser_id}`, requestOptionsCreate);
                this.setState({
                    users: await this.getUsers(this.state.loggedUser),
                    loading: false
                });

                if (responseCreate.ok) {
                    this.setSuccessMessage('Se ha creado el usuario correctamente.')
                    this.clearSelection();
                }
                else {
                    this.setAlertMessage('No pudimos crear el usuario.');
                }
                break;
            default:
                this.clearSelection();
                break;
        }
        this.setState({
            loading: false
        });
    };

    checkField = (inputValue, validationObj) => {
        const validationResult = validateField(inputValue, validationObj);
        if (validationResult === '') {
            return true;
        }
        this.setAlertMessage(validationResult);
        return false;
    };

    validateInputs = () => {        
        if (!this.checkField(this.state.selectedUser_nombre, usuarioObj)) {
            return false;
        }
        if (!this.checkField(this.state.selectedUser_mail, mailObj)) {
            return false;
        }
        if (!this.checkField(this.state.selectedUser_codigo_area, codAreaObj)) {
            return false;
        }
        if (!this.checkField(this.state.selectedUser_celular, celularObj)) {
            return false;
        }
        if (!this.state.jerarquias.includes(this.state.selectedUser_jerarquia)) {
            this.setAlertMessage('Jerarquia invalida');
            return false;
        }
        if (getReservedUsername(this.state.selectedUser_nombre)) {
            this.setAlertMessage('No puede usar este nombre de usuario. El mismo se encuentra reservado por el sistema');
            return false;
        }
        return true;
    }

    handleCancelConfirmation = () => {
        this.clearConfirmation();
    };

    handleRequestSendPasswordConfirmationViaSMS = () => {
        this.setupConfirmation("SMS");
    };

    handleRequestSendPasswordConfirmationViaMail = () => {
        this.setupConfirmation("Mail");
    };

    handleCancelRequest = () => {
        this.setupConfirmation("Cancelar");
    }

    handleSaveRequest = () => {
        this.setupConfirmation("Guardar");
    }

    handleDeleteRequest = () => {
        this.setupConfirmation("Eliminar");
    }

    handleCreateRequest = () => {
        this.setupConfirmation("Crear");
    }

    getMainPage = () => {
        return (
            <div style={{ textAlign: "center" }}>
                {this.getMessageArea()}
                {<SimpleConfirmationComponent title={this.state.confirmationTitle} message={this.state.confirmationMessage} btnOkText="Si" btnCancelText="No" onOk={this.handleOkConfirmation} onCancel={this.handleCancelConfirmation} onClose={this.handleCancelConfirmation} />}
                <table className='table table-striped mini-table'>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Jerarquia</th>
                            <th>Activo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.users.map(user =>
                            <tr key={user.id} onClick={() => this.rowClickHandler(user.id)}>
                                <td>{user.nombre}</td>
                                <td>{user.idJerarquia}</td>
                                <td>{user.activo ? "Si" : "No"}</td>
                            </tr>
                        )}
                        <tr>
                            <td colSpan="3">
                                <BsFillPlusCircleFill style={{ fontSize: "2rem", color: "var(--blueColor)" }} className="update-input-data-btn" alt="Agregar" title="Agregar" onClick={this.addNewUser}></BsFillPlusCircleFill>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    getEditPage = () => {
        return (
            <div style={{ textAlign: "center" }}>
                {this.getMessageArea()}
                {<SimpleConfirmationComponent title={this.state.confirmationTitle} message={this.state.confirmationMessage} btnOkText="Si" btnCancelText="No" onOk={this.handleOkConfirmation} onCancel={this.handleCancelConfirmation} onClose={this.handleCancelConfirmation} />}
                <table className='table table-striped mini-table'>
                    <thead>
                        <tr>
                            <th colSpan="3">
                                Editar Usuario
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <b>Nombre:</b>
                            </td>
                            <td>
                                <input type="text" minLength={usuarioObj.minLength} maxLength={usuarioObj.maxLength} className="single-input" style={{ textAlign: "center" }} value={this.state.selectedUser_nombre} onChange={(e) => this.setState({ selectedUser_nombre: e.target.value })}></input>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Mail:</b>
                            </td>
                            <td>
                                <input type="email" minLength={mailObj.minLength} maxLength={mailObj.maxLength} className="single-input" style={{ textAlign: "center" }} value={this.state.selectedUser_mail} onChange={(e) => this.setState({ selectedUser_mail: e.target.value })}></input>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Codigo De Area:</b>
                            </td>
                            <td>
                                <input type="number" minLength={codAreaObj.minLength} maxLength={codAreaObj.maxLength} className="single-input" style={{ textAlign: "center" }} value={this.state.selectedUser_codigo_area} onChange={(e) => this.setState({ selectedUser_codigo_area: e.target.value })}></input>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Celular:</b>
                            </td>
                            <td>
                                <input type="number" minLength={celularObj.minLength} maxLength={celularObj.maxLength} className="single-input" style={{ textAlign: "center" }} value={this.state.selectedUser_celular} onChange={(e) => this.setState({ selectedUser_celular: e.target.value })}></input>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Clave:</b>
                            </td>
                            <td>
                                <BsPhoneVibrateFill style={{ fontSize: "1.2rem", color: "var(--bs-success)" }} className="update-input-data-btn" alt="Enviar nueva clave por SMS" title="Enviar nueva clave por SMS" onClick={this.handleRequestSendPasswordConfirmationViaSMS}></BsPhoneVibrateFill>
                                <BsFillEnvelopeAtFill style={{ fontSize: "1.2rem", color: "var(--bs-success)" }} className="update-input-data-btn" alt="Enviar nueva clave por Mail" title="Enviar nueva clave por Mail" onClick={this.handleRequestSendPasswordConfirmationViaMail}></BsFillEnvelopeAtFill>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Jerarquia:</b>
                            </td>
                            <td>
                                <select id="cboJerarquias" defaultValue={this.state.selectedUser_jerarquia} onChange={(e) => this.setState({ selectedUser_jerarquia: e.target.value })}>
                                    {this.state.jerarquias.map(jerarquia =>
                                        <option key={jerarquia} value={jerarquia}>{jerarquia}</option>
                                    )}
                                </select>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Activo:</b>
                            </td>
                            <td>
                                <input type="checkbox" className="single-input" style={{ textAlign: "center" }} checked={this.state.selectedUser_activo} onChange={(e) => this.setState({ selectedUser_activo: Boolean(e.target.checked) })}></input>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3">
                                <BsFillCheckCircleFill style={{ fontSize: "2rem", color: "var(--bs-success)" }} className="update-input-data-btn" alt="Guardar" title="Guardar" onClick={this.handleSaveRequest}></BsFillCheckCircleFill>
                                <BsFillXCircleFill style={{ fontSize: "2rem", color: "var(--bs-warning)" }} className="update-input-data-btn" alt="Cancelar" title="Cancelar" onClick={this.handleCancelRequest}></BsFillXCircleFill>
                                <BsTrashFill style={{ fontSize: "2rem", color: "var(--bs-danger)" }} className="update-input-data-btn" alt="Eliminar" title="Eliminar" onClick={this.handleDeleteRequest}></BsTrashFill>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    getCreatePage = () => {
        return (
            <div style={{ textAlign: "center" }}>
                {this.getMessageArea()}
                {<SimpleConfirmationComponent title={this.state.confirmationTitle} message={this.state.confirmationMessage} btnOkText="Si" btnCancelText="No" onOk={this.handleOkConfirmation} onCancel={this.handleCancelConfirmation} onClose={this.handleCancelConfirmation} />}
                <table className='table table-striped mini-table'>
                    <thead>
                        <tr>
                            <th colSpan="3">
                                Crear Usuario
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <b>Nombre:</b>
                            </td>
                            <td>
                                <input type="text" minLength={usuarioObj.minLength} maxLength={usuarioObj.maxLength} className="single-input" style={{ textAlign: "center" }} value={this.state.selectedUser_nombre} onChange={(e) => this.setState({ selectedUser_nombre: e.target.value })}></input>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Mail:</b>
                            </td>
                            <td>
                                <input type="email" minLength={mailObj.minLength} maxLength={mailObj.maxLength} className="single-input" style={{ textAlign: "center" }} value={this.state.selectedUser_mail} onChange={(e) => this.setState({ selectedUser_mail: e.target.value })}></input>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Codigo De Area:</b>
                            </td>
                            <td>
                                <input type="number" minLength={codAreaObj.minLength} maxLength={codAreaObj.maxLength} className="single-input" style={{ textAlign: "center" }} value={this.state.selectedUser_codigo_area} onChange={(e) => this.setState({ selectedUser_codigo_area: e.target.value })}></input>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Celular:</b>
                            </td>
                            <td>
                                <input type="number" minLength={celularObj.minLength} maxLength={celularObj.maxLength} className="single-input" style={{ textAlign: "center" }} value={this.state.selectedUser_celular} onChange={(e) => this.setState({ selectedUser_celular: e.target.value })}></input>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Jerarquia:</b>
                            </td>
                            <td>
                                <select id="cboJerarquias" defaultValue={this.state.selectedUser_jerarquia} onChange={(e) => this.setState({ selectedUser_jerarquia: e.target.value })}>
                                    {this.state.jerarquias.map(jerarquia =>
                                        <option key={jerarquia} value={jerarquia}>{jerarquia}</option>
                                    )}
                                </select>
                            </td>
                            <td>
                                {getRequiredIcon()}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3">
                                <BsFillCheckCircleFill style={{ fontSize: "2rem", color: "var(--bs-success)" }} className="update-input-data-btn" alt="Crear" title="Crear" onClick={this.handleCreateRequest}></BsFillCheckCircleFill>
                                <BsFillXCircleFill style={{ fontSize: "2rem", color: "var(--bs-warning)" }} className="update-input-data-btn" alt="Cancelar" title="Cancelar" onClick={this.handleCancelRequest}></BsFillXCircleFill>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    getLandingPage = () => {
        return (this.state.selectedUser_id == null ? this.getMainPage() : this.state.selectedUser_id === 0 ? this.getCreatePage() : this.getEditPage());
    };

    render() {
        return this.state.loading ? this.getRender() : this.state.isAdminUser ? this.getLandingPage() : this.getUnauthorizedPage();
    }
}
