import { React } from 'react';
import { Shared } from '../components/Shared';
import { IsUserAdmin } from '../utils';
import Card from 'react-bootstrap/Card';

export class Reports extends Shared {
    static displayName = Reports.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            adminUser: false
        };
    }

    componentDidMount() {
        this.populateData();
    }

    populateData = async () => {
        this.setState({
            loading: false,
            adminUser: IsUserAdmin()
        });
    }

    goToViewUsuarios = () => {
        window.location.replace("/reports/users");
    }

    goToViewAlarms = () => {
        window.location.replace("/reports/alarms");
    }

    goToViewGroupedAlarms = () => {
        window.location.replace("/reports/groupedAlarms");
    }

    goToEmpty = () => {
        console.log('working');
    }
    
    getOptionCards() {
        return (
            <div style={{ width: "100%", height: "100%", textAlign: "Center" }}>

                {this.state.adminUser && (
                    <div className="floatingDiv configCardHolder">
                        <Card onClick={this.goToViewUsuarios}>
                            <Card.Body>
                                <Card.Title>Reporte Usuarios</Card.Title>
                                <Card.Text>
                                    Visualize los usuarios de su empresa
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                )}

                <div className="floatingDiv configCardHolder">
                    <Card onClick={this.goToViewAlarms}>
                        <Card.Body>
                            <Card.Title>Reporte Alarmas Individuales</Card.Title>
                            <Card.Text>
                                Visualize las alarmas individuales de su empresa
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>

                <div className="floatingDiv configCardHolder">
                    <Card onClick={this.goToViewGroupedAlarms}>
                        <Card.Body>
                            <Card.Title>Reporte Alarmas Agrupadas</Card.Title>
                            <Card.Text>
                                Visualize las alarmas agrupadas de su empresa
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>

                {/*{this.state.adminUser && (*/}
                {/*<div className="floatingDiv configCardHolder">*/}
                {/*    <Card onClick={this.goToEmpty}>*/}
                {/*        <Card.Body>*/}
                {/*            <Card.Title>Gestion Empresa</Card.Title>*/}
                {/*            <Card.Text>*/}
                {/*                Visualize la configuracion de la empresa*/}
                {/*            </Card.Text>*/}
                {/*        </Card.Body>*/}
                {/*    </Card>*/}
                {/*    </div>*/}
                {/*)}*/}
                
                {/*{this.state.adminUser && (*/}
                {/*<div className="floatingDiv configCardHolder">*/}
                {/*    <Card onClick={this.goToEmpty}>*/}
                {/*        <Card.Body>*/}
                {/*            <Card.Title>Gestion Alarmas Individuales</Card.Title>*/}
                {/*            <Card.Text>*/}
                {/*                Visualize la configuracion de alarmas individuales*/}
                {/*            </Card.Text>*/}
                {/*        </Card.Body>*/}
                {/*    </Card>*/}
                {/*    </div>*/}
                {/*)}*/}
                
                {/*{this.state.adminUser && (*/}
                {/*<div className="floatingDiv configCardHolder">*/}
                {/*    <Card onClick={this.goToEmpty}>*/}
                {/*        <Card.Body>*/}
                {/*            <Card.Title>Gestion Alarmas Agrupadas</Card.Title>*/}
                {/*            <Card.Text>*/}
                {/*                Visualize la configuracion de alarmas agrupadas*/}
                {/*            </Card.Text>*/}
                {/*        </Card.Body>*/}
                {/*    </Card>*/}
                {/*</div>*/}
                {/*)}*/}
            </div>
        );
    }

    render() {
        return this.state.loading ? this.getRender() : this.getOptionCards();
    }
}