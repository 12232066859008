import { ResponsiveContainer, LineChart, Line, CartesianGrid, Tooltip, Legend, BarChart, Bar, PieChart, Pie, Cell, Rectangle, XAxis, YAxis } from 'recharts';

function emptyApiDataByHours() {
    const result = [];
    const dateParts = new Date();
    const year = dateParts.getFullYear();
    const month = dateParts.getMonth() + 1;
    const day = dateParts.getDate();
    for (let hour = 0; hour < 24; hour++) {
        const sum = 0;
        const full_month = String(month).padStart(2, '0');
        const full_day = String(day).padStart(2, '0');
        const full_hour = String(hour).padStart(2, '0');
        const full_minute = String("0").padStart(2, '0');
        const dt = `${year}-${full_month}-${full_day} ${full_hour}:${full_minute}`;
        const hours = String(full_hour) + ":00";
        result.push({
            dt: dt,
            datetime: new Date(year, month - 1, day, hour, 0),
            horarioCorto: hours,
            alarmas: sum,
        });
    }
    return result;
}

function flattenApiDataByHours(currentData, gmt) {
    if (currentData === null || currentData === undefined || currentData.length === 0)
    {
        return emptyApiDataByHours();
    }

    const parsedData = currentData.map(item => {
        const utc = new Date(item.creadoUTC);
        const final = new Date(utc.setHours(utc.getHours() + gmt));
        const newItem = { ...item, TMZ: final };
        return newItem;
    });

    const uniqueDates = [...new Set(parsedData.map(item => item.TMZ.toISOString().split('T')[0]))];

    const result = [];
    uniqueDates.forEach(fecha => {
        const dateParts = fecha.split('-').map(Number);
        const year = dateParts[0];
        const month = dateParts[1];
        const day = dateParts[2];
        const full_month = String(month).padStart(2, '0');
        const full_day = String(day).padStart(2, '0');
        for (let hour = 0; hour < 24; hour++) {
            const test = new Date(year, month - 1, day, hour, 0, 0);
            const diffHour = new Date(test.setHours(test.getHours() + gmt));
            const full_hour = String(hour).padStart(2, '0');
            const full_minute = String("0").padStart(2, '0');
            const filteredData = parsedData.filter(item => item.TMZ.toISOString().startsWith(diffHour.toISOString().slice(0, 13)));
            const sum = filteredData.length ?? 0;
            const dt = `${year}-${full_month}-${full_day} ${full_hour}:${full_minute}`;
            const hours = String(full_hour) + ":00";
            result.push({
                dt: dt,
                datetime: new Date(year, month, day, hour, 0, 0, 0),
                horarioCorto: hours,
                alarmas: sum,
            });
        }
    });
    return result;
}

function emptyApiDataByLugar() {
    const result = [{ lugar: "Ninguna", suma: 0, objetos: [{ objeto: "Ninguno", suma: 0 }]}];
    return result;
}

function flattenApiDataByLugar(currentData) {
    if (currentData === null || currentData === undefined || currentData.length === 0) {
        return emptyApiDataByLugar();
    }
    const result = [];

    currentData.forEach(item => {
        const lugarIndex = result.findIndex(group => group.lugar === item.lugar);

        if (lugarIndex === -1) {
            result.push({
                lugar: item.lugar,
                suma: 1,
                objetos: [{ objeto: item.objeto, suma: 1 }]
            });
        } else {
            result[lugarIndex].suma++;

            const objetoIndex = result[lugarIndex].objetos.findIndex(obj => obj.objeto === item.objeto);
            if (objetoIndex === -1) {
                result[lugarIndex].objetos.push({ objeto: item.objeto, suma: 1 });
            } else {
                result[lugarIndex].objetos[objetoIndex].suma++;
            }
        }
    });
    return result;
}

function renderLineChart(results, dataKey, dataName, color) {
    if (color === null || color === undefined || color.length === 0) {
        color = getRandomColor();
    }
    const rnd = String(Math.random());
    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart key={{ rnd }} width={600} height={450} data={results} margin={{ top: 5, right: 20, left: 20, bottom: 150 }}>
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <Tooltip />
                <XAxis dataKey={dataName} angle="90" tickMargin="5" textAnchor="1" />
                <YAxis allowDecimals={false} interval={0} />
                <Line type="monotone" dataKey={dataKey} stroke={color} />
            </LineChart>
        </ResponsiveContainer>
    );
}

function getRandomColor() {
    const randomIndex = Math.floor(Math.random() * CHART_COLORS.length);
    return CHART_COLORS[randomIndex];
}

const CHART_COLORS = [
    '#4CAF50', '#2196F3', '#FFC107', '#E91E63',
    '#FF5722', '#9C27B0', '#FFEB3B', '#00BCD4',
    '#673AB7', '#FF9800', '#795548', '#03A9F4',
    '#CDDC39', '#8BC34A', '#FF4081', '#FF5252',
    '#607D8B', '#9E9E9E', '#F44336', '#00ACC1',
    '#9C27B0', '#2196F3', '#FFC107', '#E91E63',
    '#FF5722', '#9C27B0', '#FFEB3B', '#00BCD4',
    '#673AB7', '#FF9800', '#795548', '#03A9F4',
    '#CDDC39', '#8BC34A', '#FF4081', '#FF5252',
    '#607D8B', '#9E9E9E', '#F44336', '#00ACC1',
    '#9C27B0', '#2196F3', '#FFC107', '#E91E63',
    '#FF5722', '#9C27B0', '#FFEB3B', '#00BCD4',
    '#673AB7', '#FF9800', '#795548', '#03A9F4',
    '#CDDC39', '#8BC34A', '#FF4081', '#FF5252',
    '#607D8B', '#9E9E9E', '#F44336', '#00ACC1'
];

function renderPieChart(results, dataKey, dataName) {
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };
    const rnd = String(Math.random());

    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart key={{ rnd }} width={400} height={450} margin={{ top: 5, right: 20, left: 20, bottom: 50 }}>
                <Pie
                    data={results}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey={dataKey}
                    nameKey={dataName}>
                    {results.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={CHART_COLORS[index % CHART_COLORS.length]} />
                    ))}
                </Pie>
                <Legend wrapperStyle={{ position: "relative", right: "0px", left: "0px", bottom: "100px"}} />
            </PieChart>
        </ResponsiveContainer>
    );
}

function renderBarChart(results, dataKey, dataName, color) {
    if (color === null || color === undefined || color.length === 0) {
        color = getRandomColor();
    }
    const rnd = String(Math.random());
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart key={{ rnd }} width={500} height={450} data={results} margin={{ top: 5, right: 20, left: 20, bottom: 50 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={dataName} />
                <YAxis allowDecimals={false} interval={0} />
                <Bar dataKey={dataKey} fill={color} activeBar={<Rectangle fill="pink" stroke="blue" />} />
            </BarChart>
        </ResponsiveContainer>
    );
}

export { flattenApiDataByHours, flattenApiDataByLugar, renderLineChart, renderPieChart, renderBarChart, CHART_COLORS, getRandomColor };
