import React from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { performLogout, IsUserAdmin } from '../utils';
import { productInfo } from '../App'
import { SimpleConfirmationComponent } from './Alerts';

export function NavMenu(props) {
    const [confirmationTitle, setConfirmationTitle] = React.useState('');
    const [confirmationMessage, setConfirmationMessage] = React.useState('');
    const [collapsed, setCollapsed] = React.useState(true);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    };

    const handleOkLogout = async (e) => {
        setConfirmationTitle('');
        setConfirmationMessage('');
        await performLogout(props);
    };

    const handleCancelLogout = () => {
        setConfirmationTitle('');
        setConfirmationMessage('');
    };

    const handleClosedLogout = () => {
        setConfirmationTitle('');
        setConfirmationMessage('');
    };

    const requestLogout = () => {
        setConfirmationTitle('Logout');
        setConfirmationMessage(`Desea cerrar sesion?`);
    };

    const getUserNav = () => {
        return (
            <header>
                <SimpleConfirmationComponent title={confirmationTitle} message={confirmationMessage} onOk={handleOkLogout} btnOkText="Si" btnCancelText="No" onCancel={handleCancelLogout} onClose={handleClosedLogout} />
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container dark>
                    <NavbarBrand tag={Link} to="/">{productInfo.appName}</NavbarBrand>
                    <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                <NavLink tag={Link} className="text-white" to="/" onClick={toggleNavbar}>Dashboard</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-white" to="/reports" onClick={toggleNavbar}>Reportes</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-white" to="/profile" onClick={toggleNavbar}>Perfil</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-white" onClick={requestLogout}>Logout</NavLink>
                            </NavItem>
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    };

    const getAdminNav = () => {
        return (
            <header>
                <SimpleConfirmationComponent title={confirmationTitle} message={confirmationMessage} onOk={handleOkLogout} btnOkText="Si" btnCancelText="No" onCancel={handleCancelLogout} onClose={handleClosedLogout} />
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container dark>
                    <NavbarBrand tag={Link} to="/">{productInfo.appName}</NavbarBrand>
                    <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                <NavLink tag={Link} className="text-white" to="/" onClick={toggleNavbar}>Dashboard</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-white" to="/reports" onClick={toggleNavbar}>Reportes</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-white" to="/config" onClick={toggleNavbar}>Configuraciones</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-white" to="/profile" onClick={toggleNavbar}>Perfil</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-white" onClick={requestLogout}>Logout</NavLink>
                            </NavItem>
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    };

    const getNav = () => {
        if (IsUserAdmin()) {
            return getAdminNav();
        }
        else {
            return getUserNav();
        }
    }

    return getNav();
}
