import 'bootstrap/dist/css/bootstrap.css';
import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import LoginComponent from './login';
import { getSessionVariable, storedSession } from './utils';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

function Index() {
    const getAutenticated = () => {
        const storedUsername = getSessionVariable(storedSession.sessionName);
        if (storedUsername) {
            return true;
        }
        return false;
    };
    const [authenticated, setAuthenticated] = useState(getAutenticated);

    if (!authenticated) {
        return <LoginComponent setAuthenticated={setAuthenticated} />;
    }
    return (
        <BrowserRouter basename={baseUrl}>
            <App setAuthenticated={setAuthenticated} />
        </BrowserRouter>
    );
}

root.render(<Index />);
