import { React } from 'react';
import { Shared } from '../components/Shared';
import { IsUserAdmin } from '../utils';
import Card from 'react-bootstrap/Card';

export class Config extends Shared {
    static displayName = Config.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            adminUser: false
        };
    }

    componentDidMount() {
        this.populateData();
    }

    populateData = async () => {
        this.setState({
            loading: false,
            adminUser: IsUserAdmin()
        });
    }

    goToUsuarios = () => {
        window.location.replace("/config/users");
    }    

    goToAlarms = () => {
        window.location.replace("/config/alarms");
    }   
    goToAlarmsTransmission = () => {
        window.location.replace("/config/groupedAlarms");
    }   

    getOptionCards() {
        return (
            <div style={{ width: "100%", height: "100%", textAlign: "Center" }}>

                <div className="floatingDiv configCardHolder">
                    <Card onClick={this.goToUsuarios}>
                        <Card.Body>
                            <Card.Title>Usuarios</Card.Title>
                            <Card.Text>
                                Gestione los usuarios de su empresa
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>

                <div className="floatingDiv configCardHolder">
                    <Card onClick={this.goToAlarms}>
                        <Card.Body>
                            <Card.Title>Alarmas Individuales</Card.Title>
                            <Card.Text>
                                Gestione las alarmas analizadas por evento individual
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>

                <div className="floatingDiv configCardHolder">
                    <Card onClick={this.goToAlarmsTransmission}>
                        <Card.Body>
                            <Card.Title>Alarmas Agrupadas</Card.Title>
                            <Card.Text>
                                Gestione las alarmas analizadas por proceso agrupado
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        );
    }

    render() {
        return this.state.loading ? this.getRender() : this.state.adminUser ? this.getOptionCards() : this.getUnauthorizedPage();
    }
}
