import { React } from 'react';
import { Shared } from '../components/Shared';
import { getCurrentSession, getDateTimeDifferenceInMinutes, getFechaDesde, getFechaHasta, getFechaMaxima, getFechaMinima } from '../utils';

import { CHART_COLORS, flattenApiDataByHours, flattenApiDataByLugar, renderBarChart, renderLineChart, renderPieChart } from '../components/Chart';

export class Home extends Shared {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = {
            fechaDesdeValue: getFechaDesde(-1),
            fechaHastaValue: getFechaHasta(0),
            flattenDataByHorarios: [],
            flattenDataByLugares: [],
            flattenDataTransmissionByHorarios: [],
            flattenDataTransmissionByLugares: [],
            loggedUser: null,
            loading: true
        };
    }

    componentDidMount() {
        this.populateData(true);
    }

    populateData = async (removeRender) => {
        const loggedUser = getCurrentSession();
        if (loggedUser === null || loggedUser === undefined) {
            if (removeRender) {
                this.setState({
                    loading: false
                });
            }
            return;
        }

        this.setState({
            loggedUser: loggedUser
        });

        await this.GetLatestAlarmsData(loggedUser);
        await this.GetLatestAlarmsTransmission(loggedUser);

        if (removeRender) {
            this.setState({
                loading: false
            });
        }
    }

    GetLatestAlarmsData = async (loggedUser) => {
        const response = await fetch(`Main/GetLatestAlarms/${loggedUser.empresa.id}/${loggedUser.id}?desde=${this.state.fechaDesdeValue}&hasta=${this.state.fechaHastaValue}`);
        if (response.ok) {
            const data = await response.json();
            const flattenDataByHorarios = flattenApiDataByHours(data, loggedUser.empresa.gmt);
            const flattenDataByLugares = flattenApiDataByLugar(data, loggedUser.empresa.gmt);
            this.setState({
                flattenDataByHorarios: flattenDataByHorarios,
                flattenDataByLugares: flattenDataByLugares
            });
            return true;
        }
        else {
            return false;
        }
    }

    GetLatestAlarmsTransmission = async (loggedUser) => {
        const response = await fetch(`Main/GetLatestAlarmsTransmision/${loggedUser.empresa.id}/${loggedUser.id}?desde=${this.state.fechaDesdeValue}&hasta=${this.state.fechaHastaValue}`);
        if (response.ok) {
            const data = await response.json();
            const modifiedData = data.map(item => {
                const newItem = { ...item, lugar: "Agrupadas", objeto: "Varios" };
                return newItem;
            });
            const flattenDataTransmissionByHorarios = flattenApiDataByHours(data, loggedUser.empresa.gmt);
            const flattenDataTransmissionByLugares = flattenApiDataByLugar(modifiedData, loggedUser.empresa.gmt);
            this.setState({
                flattenDataTransmissionByHorarios: flattenDataTransmissionByHorarios,
                flattenDataTransmissionByLugares: flattenDataTransmissionByLugares
            });
            return true;
        }
        else {
            return false;
        }
    }

    updatePeriodo = () => {
        const diasPermitidos = 30;
        if (!getDateTimeDifferenceInMinutes(this.state.fechaDesdeValue, this.state.fechaHastaValue, diasPermitidos)) {
            this.setAlertMessage(`El rango de fechas no puede superar los ${diasPermitidos} dias`);
            return;
        }
        this.setState({
            loading: true
        });
        setTimeout(() => {
            this.populateData(true);
        }, 500);
        return;
    }

    getLandingPage = () => {
        return (
            <>
                {this.getMessageArea()}
                <div className="floatingDiv" style={{ width: "100%", marginTop: "10px", marginBottom: "15px" }}>
                    <div className="floatingDiv" style={{ width: "75px", minWidth: "75px", maxWidth: "75px" }}>
                        <label style={{ padding: "5px" }}>Desde:</label>
                    </div>
                    <div className="floatingDiv" style={{ width: "200px", minWidth: "200px", maxWidth: "200px" }}>
                        <input type="datetime-local" id="DTFrom" value={this.state.fechaDesdeValue} min={getFechaMinima()} max={getFechaMaxima()} onChange={(e) => this.setState({ fechaDesdeValue: e.target.value })} />
                    </div>
                    <div className="floatingDiv" style={{ width: "75px", minWidth: "75px", maxWidth: "75px" }}>
                        <label style={{ padding: "5px" }}>Hasta:</label>
                    </div>
                    <div className="floatingDiv" style={{ width: "200px", minWidth: "200px", maxWidth: "200px" }}>
                        <input type="datetime-local" id="DTTo" value={this.state.fechaHastaValue} min={getFechaMinima()} max={getFechaMaxima()} onChange={(e) => this.setState({ fechaHastaValue: e.target.value })} />
                    </div>

                    <div className="floatingDiv" style={{ textAlign: "center", width: "200px", minWidth: "200px", maxWidth: "200px" }}>
                        <button style={{ width: "150px", minWidth: "150px", maxWidth: "150px" }} onClick={(e) => this.updatePeriodo()}>Buscar</button>
                    </div>
                </div>

                <p><b>Alarmas Individuales</b></p>
                <div className="floatingDiv homeLineChart">
                    {renderLineChart(this.state.flattenDataByHorarios, "alarmas", "dt", CHART_COLORS[0])}
                </div>
                <div className="floatingDiv homeBarChart">
                    {renderBarChart(this.state.flattenDataByLugares, "suma", "lugar", CHART_COLORS[0])}
                </div>
                <div className="floatingDiv homePieChart">
                    {renderPieChart(this.state.flattenDataByLugares, "suma", "lugar")}
                </div>

                <p><b>Alarmas Agrupadas</b></p>
                <div className="floatingDiv homeLineChartGroup">
                    {renderLineChart(this.state.flattenDataTransmissionByHorarios, "alarmas", "dt", CHART_COLORS[1])}
                </div>
                <div className="floatingDiv homeBarChartGroup">
                    {renderBarChart(this.state.flattenDataTransmissionByLugares, "suma", "lugar", CHART_COLORS[1])}
                </div>

                <br />
                <br />
            </>
        );
    };

    render() {
        return this.state.loading ? this.getRender() : this.getLandingPage();
    }
}
