import { React } from 'react';
import { Shared } from '../../components/Shared';
import { getRowIcon, orderReportData, getUsers, IsUserAdmin, exportToExcel, getCurrentSession, getJerarquias } from '../../utils';

export class ReportsUsers extends Shared {
    static displayName = ReportsUsers.name;

    constructor(props) {
        super(props);
        this.state = {
            loggedUser: null,
            isAdminUser: false,
            filterOnlyActivos: false,
            checkedOptions: ["Todos", "Solo Activos", "Solo Inactivos"],
            checkedOption: "Todos",
            jerarquia: "Todas",
            jerarquias: [],
            users: [],
            loading: true,
            lastOrderField: "nombre",
            lastOrderDirection: "ASC"
        };
    }

    componentDidMount() {
        this.populateData();
    }
    
    populateData = async () => {
        const loggedUser = getCurrentSession();
        const jerarquias = await getJerarquias();
        jerarquias.push("Todas");

        this.setState({
            loggedUser: loggedUser,
            isAdminUser: IsUserAdmin(),
            jerarquias: jerarquias,
            loading: false
        });
    }

    isReportVisible = () => {
        return (this.state.users === null || this.state.users === undefined || this.state.users.length === 0);
    }

    buscar = async () => {
        this.setState({
            loading: true,
            lastOrderField: "nombre",
            lastOrderDirection: "ASC",
            users: []
        });
        let users = await getUsers(this.state.loggedUser.empresa.id, this.state.jerarquia, this.state.checkedOption);
        if (users === null || users === undefined || users.length === 0) {
            this.setAlertMessage("No hay datos para visualizar");
        }
        users = orderReportData(users, "nombre", "ASC");
        users = users.map((item, index) => (
            {
                id: item.id,
                nombre: item.nombre,
                mail: item.mail,
                telefono: `${item.codigoArea}-${item.celular}`,
                idJerarquia: item.idJerarquia,
                activo: item.activo ? "Si" : "No"
            }
        ));
        this.setState({
            users: users,
            loading: false
        });
    }
    
    cerrar = async () => {
        this.setState({
            users: []
        });
    }
    
    processNewOrder = (clickedItem) => {
        let orderDirection = this.state.lastOrderDirection;
        if (this.state.lastOrderField === clickedItem && orderDirection === 'DESC') {
            orderDirection = "ASC";
        } else {
            orderDirection = "DESC";
        }

        this.setState({
            lastOrderField: clickedItem,
            lastOrderDirection: orderDirection,
            users: orderReportData(this.state.users, clickedItem, orderDirection)
        });
    }
    
    getMainPage = () => {
        return (
            <>
                {this.getMessageArea()}
                <div className="reportHeader" style={{ display: this.isReportVisible() ? "inherit" : "none" }}>
                    <h4>Reporte Usuarios</h4>
                    <p>
                        <label htmlFor="CboJerarquias">Jerarquia: </label>
                        <select id="CboJerarquias" defaultValue={this.state.jerarquia} onChange={(e) => this.setState({ jerarquia: e.target.value })}>
                            {this.state.jerarquias.map(jerarquia =>
                                <option key={jerarquia} value={jerarquia}>{jerarquia}</option>
                            )}
                        </select>
                    </p>
                    <p>
                        <label htmlFor="CboCheckedOptions">Activos: </label>
                        <select id="CboCheckedOptions" defaultValue={this.state.checkedOption} onChange={(e) => this.setState({ checkedOption: e.target.value })}>
                            {this.state.checkedOptions.map(checkedOption =>
                                <option key={checkedOption} value={checkedOption}>{checkedOption}</option>
                            )}
                        </select>
                    </p>
                    <p>
                        <button onClick={(e) => this.buscar()}>Buscar</button>
                    </p>
                </div>

                <div className="reportHeader" style={{ display: this.isReportVisible() ? "none" : "inherit" }}>
                    <h4>Reporte Usuarios</h4>
                    <p>
                        <button style={{ backgroundColor: "var(--bs-success)", border: "1px solid var(--bs-success)" }} onClick={(e) => exportToExcel('MainTbl', 'Reporte_Usuarios')}>Exportar a Excel</button>
                        <button onClick={(e) => this.cerrar()}>Cerrar</button>
                    </p>
                </div>

                <div style={{ textAlign: "center", display: this.isReportVisible() ? "none" : "inherit" }}>
                    <table id="MainTbl" className='table table-striped report_table'>
                        <thead>
                            <tr>
                                <th style={{ cursor: "pointer" }} onClick={(e) => this.processNewOrder('nombre')}>Nombre {getRowIcon(this.state.lastOrderField, this.state.lastOrderDirection,'nombre')}</th>
                                <th style={{ cursor: "pointer" }} onClick={(e) => this.processNewOrder('mail')}>Mail {getRowIcon(this.state.lastOrderField, this.state.lastOrderDirection,'mail')}</th>
                                <th style={{ cursor: "pointer" }} onClick={(e) => this.processNewOrder('telefono')}>Celular {getRowIcon(this.state.lastOrderField, this.state.lastOrderDirection,'telefono')}</th>
                                <th style={{ cursor: "pointer" }} onClick={(e) => this.processNewOrder('idJerarquia')}>Jerarquia {getRowIcon(this.state.lastOrderField, this.state.lastOrderDirection,'idJerarquia')}</th>
                                <th style={{ cursor: "pointer" }} onClick={(e) => this.processNewOrder('activo')}>Activo {getRowIcon(this.state.lastOrderField, this.state.lastOrderDirection,'activo')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.users.map(user =>
                                <tr key={user.id}>
                                    <td>{user.nombre}</td>
                                    <td>{user.mail}</td>
                                    <td>{user.telefono}</td>
                                    <td>{user.idJerarquia}</td>
                                    <td>{user.activo}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </>
        );
    };

    render() {
        return this.state.loading ? this.getRender() : this.state.isAdminUser ? this.getMainPage() : this.getUnauthorizedPage();
    }
}