import { BsPhoneVibrateFill, BsFillEnvelopeAtFill, BsWhatsapp, BsDot, BsCaretDownFill, BsCaretUpFill, BsGearFill } from "react-icons/bs";
import { FaGreaterThan, FaGreaterThanEqual, FaLessThan, FaLessThanEqual, FaEquals, FaNotEqual, FaAsterisk } from "react-icons/fa";
import * as html2pdf from 'html3pdf';

const { EncryptStorage } = require('encrypt-storage');

export const empresaObj = {
    label: "Empresa",
    minLength: 3,
    maxLength: 150,
    validationRegex: /^[a-zA-Z0-9-]*$/
};

export const usuarioObj = {
    label: "Usuario",
    minLength: 3,
    maxLength: 150,
    validationRegex: /^[a-zA-Z0-9-]*$/
};

export const mailObj = {
    label: "E-Mail",
    minLength: 10,
    maxLength: 150,
    validationRegex: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
};

export const claveObj = {
    label: "Clave",
    minLength: 6,
    maxLength: 50,
    validationRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+=_@#%$&!~<>?])[A-Za-z\d\-+=_@#%$&!~<>?]*$/
}; // -+=_@#%$&!~<>?

export const codigoVerificacionObj = {
    label: "Codigo Verificacion",
    minLength: 6,
    maxLength: 6,
    validationRegex: /^[0-9]+$/
};

export const codigoVerificacionMailObj = {
    label: "Codigo Verificacion",
    minLength: 18,
    maxLength: 18,
    validationRegex: /^[0-9]+$/
};

export const codAreaObj = {
    label: "Codigo de Area",
    minLength: 2,
    maxLength: 5,
    validationRegex: /^[0-9]+$/
};

export const celularObj = {
    label: "Celular",
    minLength: 5,
    maxLength: 20,
    validationRegex: /^[0-9]+$/
};

export const eventoObj = {
    label: "Evento",
    minLength: 2,
    maxLength: 150,
    validationRegex: /^[a-zA-Z0-9-]*$/
};

export const locationObj = {
    label: "Lugar",
    minLength: 2,
    maxLength: 150,
    validationRegex: /^[a-zA-Z0-9-]*$/
};

export const objectObj = {
    label: "Objeto",
    minLength: 2,
    maxLength: 150,
    validationRegex: /^[a-zA-Z0-9-]*$/
};

export const storedSession = {
    sessionName: "activeSession"
};

export const encryptStorage = new EncryptStorage('secret-key-value', {
    storageType: 'sessionStorage',
    encAlgorithm: 'Rabbit',
});

export function setSessionVariable(key, value, doNotEncript = false) {
    encryptStorage.setItem(key, value, doNotEncript);
}

export function getSessionVariable(key, doNotEncript = false) {
    const storedValue = encryptStorage.getItem(key, doNotEncript);
    return storedValue;
}

export function getCurrentSession() {
    const loggedUser = getSessionVariable(storedSession.sessionName);
    return loggedUser;
}

export function updateCurrentSession(loggedUser) {
    setSessionVariable(storedSession.sessionName, loggedUser);
}

export async function renewSession() {
    const loggedUser = getCurrentSession();
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify()
    };
    const response = await fetch(`Login/Renew/${loggedUser.idEmpresa}/${loggedUser.id}?idSesion=${loggedUser.idSesion}`, requestOptions);
    if (!response.ok) {
        return false;
    }

    const responseObj = await response.json();
    if (!responseObj) {
        return false;
    }
    const key = responseObj.key;
    if (!key) {
        return false;
    }
    if (key !== 'validUntil') {
        return false;
    }
    const value = responseObj.value;
    if (!value) {
        return false;
    }
    if (!isValidDateTimeString(value)) {
        return false;
    }

    loggedUser.validaHastaUTC = Date(value);
    updateCurrentSession(loggedUser);

    return true;
}

export function IsUserAdmin() {
    const loggedUser = getCurrentSession();
    if (loggedUser === null || loggedUser === undefined || loggedUser.idJerarquia === 0) {
        return false;
    }
    else {
        return true;
    }
}

export async function performLogout(props) {
    const loggedUser = getCurrentSession();
    logoutSession();
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify()
    };
    await fetch(`Login/Logout/${loggedUser.empresa.id}/${loggedUser.id}?idSesion=${loggedUser.idSesion}&causa=BtnLogout`, requestOptions);

    props.setAuthenticated(false);
}

export function remSessionVariable(key) {
    encryptStorage.removeItem(key);
}

export function logoutSession() {
    encryptStorage.clear();
}

export function capitalizeFirst(stringValue) {
    if (!stringValue) {
        return "";
    }
    if (stringValue.length === 1) {
        return stringValue.toUpperCase();
    }
    return stringValue.charAt(0).toUpperCase() + stringValue.slice(1).toLowerCase();
}

export function maskString(inputString) {
    if (typeof inputString !== 'string') {
        return "";
    }
    return '*'.repeat(inputString.length);
}

export function formatDateToCustomString(date, format) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const replacements = {
        'yyyy': year,
        'MM': month,
        'dd': day,
        'HH': hours,
        'mm': minutes,
        'ss': seconds,
    };

    const formattedString = format.replace(/yyyy|MM|dd|HH|mm|ss/g, match => replacements[match]);
    return formattedString;
}

export function isValidDateTimeString(dateString) {
    if (!isNaN(Date.parse(dateString))) {
        return true;
    } else {
        return false;
    }
}

export function getCurrentDateTime(gmt) {
    let currentDate = new Date();
    currentDate.setHours(currentDate.getHours() + gmt);
    return formatDateToCustomString(currentDate, 'dd/MM/yyyy HH:mm');
}

export function getEmpresaDateTime() {
    const loggedUser = getCurrentSession();
    const gmt = loggedUser.empresa.gmt;
    let currentDate = new Date();
    currentDate.setHours(currentDate.getHours() + gmt);
    return formatDateToCustomString(currentDate, 'dd/MM/yyyy HH:mm');
}

export function getTokenExpiryDateTime() {
    const loggedUser = getCurrentSession();
    const validUntil = loggedUser.validaHastaUTC;
    if (!isValidDateTimeString(validUntil)) {
        let currentDate = new Date();
        currentDate.setHours(currentDate.getHours() - 24);
        return formatDateToCustomString(currentDate, 'dd/MM/yyyy HH:mm');
    }
    const gmt = loggedUser.empresa.gmt;
    let currentDate = new Date(validUntil);
    currentDate.setHours(currentDate.getHours() + gmt);
    return formatDateToCustomString(currentDate, 'dd/MM/yyyy HH:mm');
}

export function validateField(inputValue, validationObj) {
    if (inputValue === '') {
        return (`Debes completar el campo ${validationObj.label}`);
    }
    if (inputValue.length < validationObj.minLength || inputValue.length > validationObj.maxLength) {
        return (getValidityMessage(validationObj.label, validationObj.minLength, validationObj.maxLength));
    }
    if (validationObj.validationRegex != null) {
        if (!validationObj.validationRegex.test(inputValue)) {
            return (`El campo ${validationObj.label} no cumple la validacion requerida.`);
        }
    }
    return '';
};

export function getValidityMessage(input, minLength, maxLength) {
    if (Number(minLength) === Number(maxLength)) {
        return `El campo ${input} debe tener ${minLength} caracteres.`;
    }
    return `El campo ${input} debe tener entre ${minLength} y ${maxLength} caracteres.`;
};

export function getValidationToken(code) {
    const loggedUser = getCurrentSession();
    return { code: code, gmt: loggedUser.empresa.gmt, number: loggedUser.celular };
}

export function getReservedUsername(username) {
    const reservedUsernames = [
        "admin", "administrador", "administrator",
        "soporte", "support",
        "test", "testing",
        "ialarmas",
        "system", "sistema"
    ];
    return reservedUsernames.includes(username);
}

export async function getJerarquias() {
    const responseApi = await fetch(`Main/GetJerarquias`);
    if (responseApi.ok) {
        const responseObject = await responseApi.json();
        return responseObject;
    } return [];
}

export async function getOperaciones() {
    const responseApi = await fetch(`Main/GetOperaciones`);
    if (responseApi.ok) {
        const responseObject = await responseApi.json();
        return responseObject;
    } return [];
}

export async function getMetodosEnvio() {
    const responseApi = await fetch(`Main/GetMetodosEnvio`);
    if (responseApi.ok) {
        const responseObject = await responseApi.json();
        return responseObject;
    } return [];
}


export async function getUsers(empresa_id, jerarquia = "Todas", activos = "Solo Activos") {
    const response = await fetch(`Main/GetUsers/${empresa_id}?jerarquia=${jerarquia}&activo=${activos}`);
    if (response.ok) {
        let users = await response.json();
        users = users
            .filter(function (entry) {
                return !getReservedUsername(entry.nombre);
            })
            .sort(function (a, b) {
                return a.nombre.localeCompare(b.nombre);
            });
        return users;
    }
    return [];
}

export async function getObjects(empresa_id) {
    const response = await fetch(`Main/GetObjetos/${empresa_id}`);
    if (response.ok) {
        let objects = await response.json();
        objects = objects
            .sort(function (a, b) {
                return a.localeCompare(b);
            });
        objects = objects.map(str => ({ id: str, nombre: str.toUpperCase() }));
        return objects;
    }
    return [];
}

export async function getLocations(empresa_id) {
    const response = await fetch(`Main/GetLugares/${empresa_id}`);
    if (response.ok) {
        let locations = await response.json();
        locations = locations
            .sort(function (a, b) {
                return a.localeCompare(b);
            });
        locations = locations.map(str => ({ id: str, nombre: str.toUpperCase() }));
        return locations;
    }
    return [];
}

export async function getEvents(empresa_id) {
    const response = await fetch(`Main/GetEventos/${empresa_id}`);
    if (response.ok) {
        let events = await response.json();
        events = events
            .sort(function (a, b) {
                return a.localeCompare(b);
            });
        events = events.map(str => ({ id: str, nombre: str.toUpperCase() }));
        return events;
    }
    return [];
}

export async function getAlarmConfigs(empresa_id) {
    const responseAlarmConfig = await fetch(`Main/GetAlarmConfig/${empresa_id}`);
    if (responseAlarmConfig.ok) {
        let alarmConfig = await responseAlarmConfig.json();
        alarmConfig = alarmConfig
            .filter(function (entry) {
                return entry.activo === true;
            })
            .sort(function (a, b) {
                return a.nombre.localeCompare(b).nombre;
            });
        return alarmConfig;
    }
    return [];
}

export async function getGroupedAlarmConfigs (empresa_id) {
    const responseAlarmConfig = await fetch(`Main/GetAlarmGroupConfig/${empresa_id}`);
    if (responseAlarmConfig.ok) {
        let alarmConfig = await responseAlarmConfig.json();
        alarmConfig = alarmConfig
            .filter(function (entry) {
                return entry.activo === true;
            })
            .sort(function (a, b) {
                return a.nombre.localeCompare(b).nombre;
            });
        return alarmConfig;
    }
    return [];
}

export async function getOperacionesFull() {
    const retrievedOperaciones = await getOperaciones();
    const operaciones = retrievedOperaciones.map(function (operacion) {
        return {
            valor: operacion,
            nombre: getOperacionString(operacion),
            simbolo: getOperacionSymbol(operacion)
        }
    });
    return operaciones;
}

export function getUserFromId(users, filterId)  {
    const filtered = users.filter(user => user.id === Number(filterId));
    if (filtered.length === 1) {
        return filtered[0].nombre ?? filterId;
    }
    return `id_${filterId}`;
}

export function getConfigFromId(alarmsConfig, filterId) {
    const filtered = alarmsConfig.filter(alarmConfig => alarmConfig.id === Number(filterId));
    if (filtered.length === 1) {
        return filtered[0].nombre ?? filterId;
    }
    return `id_${filterId}`;
}

export function getConfigValueFromId(alarmsConfig, filterId) {
    const filtered = alarmsConfig.filter(alarmConfig => alarmConfig.id === Number(filterId));
    if (filtered.length === 1) {
        return filtered[0].valor ?? filterId;
    }
    return `id_${filterId}`;
}

export function orderReportData (reportData, orderField, orderDirection) {
    return reportData.sort((a, b) => {
        const fieldA = a[orderField];
        const fieldB = b[orderField];

        let comparison = 0;

        if (typeof fieldA === 'string' && typeof fieldB === 'string') {
            comparison = fieldA.localeCompare(fieldB);
        } else {
            comparison = (fieldA > fieldB) ? 1 : (fieldA < fieldB) ? -1 : 0;
        }

        return orderDirection === 'DESC' ? comparison * -1 : comparison;
    });
}

export function getRowIcon(lastOrderField, lastOrderDirection, rowName) {
    if (lastOrderField === rowName) {
        if (lastOrderDirection === 'DESC') {
            return (<>
                <BsCaretDownFill style={{ color: "var(--bs-black)" }} className="mini_report_table_icon" alt="Orden Descendente" title="Orden Descendente"></BsCaretDownFill>
            </>);
        } else {
            return (<>
                <BsCaretUpFill style={{ color: "var(--bs-black)" }} className="mini_report_table_icon" alt="Orden Ascendente" title="Orden Ascendente"></BsCaretUpFill>
            </>);
        }
    } else {
        return (<>
            <BsGearFill style={{ color: "var(--bs-gray-600)" }} className="mini_report_table_icon" alt="Habilitar Filtro" title="Habilitar Filtro"></BsGearFill>
        </>);
    }
}

export function getOperacionSymbol(operacion) {
    switch (String(operacion).toUpperCase()) {
        case "NotEqual".toUpperCase():
            return "!=";
        case "Equal".toUpperCase():
            return "_";
        case "Greater".toUpperCase():
            return ">";
        case "Less".toUpperCase():
            return "<";
        case "GreaterOrEqual".toUpperCase():
            return ">=";
        case "LessOrEqual".toUpperCase():
            return "<=";
        default:
            return "?";
    }
}

export function getOperacionString(operacion) {
    switch (String(operacion).toUpperCase()) {
        case "NotEqual".toUpperCase():
            return "Diferente";
        case "Equal".toUpperCase():
            return "Igual";
        case "Greater".toUpperCase():
            return "Mayor";
        case "Less".toUpperCase():
            return "Menor";
        case "GreaterOrEqual".toUpperCase():
            return "Mayor o igual";
        case "LessOrEqual".toUpperCase():
            return "Menor o igual";
        default:
            return "?";
    }
}

export function getOperacionIcon(operacion) {
    const rnd = String(Math.random());
    switch (String(operacion).toUpperCase()) {
        case "NotEqual".toUpperCase():
            return <FaNotEqual key={rnd} style={{ fontSize: "1rem", color: "var(--blueColor)" }} className="update-input-data-btn" alt={getOperacionSymbol(operacion)} title={getOperacionString(operacion)}></FaNotEqual >;
        case "Equal".toUpperCase():
            return <FaEquals key={rnd} style={{ fontSize: "1rem", color: "var(--blueColor)" }} className="update-input-data-btn" alt={getOperacionSymbol(operacion)} title={getOperacionString(operacion)}></FaEquals>;
        case "Greater".toUpperCase():
            return <FaGreaterThan key={rnd} style={{ fontSize: "1rem", color: "var(--blueColor)" }} className="update-input-data-btn" alt={getOperacionSymbol(operacion)} title={getOperacionString(operacion)}></FaGreaterThan>;
        case "Less".toUpperCase():
            return <FaLessThan key={rnd} style={{ fontSize: "1rem", color: "var(--blueColor)" }} className="update-input-data-btn" alt={getOperacionSymbol(operacion)} title={getOperacionString(operacion)}></FaLessThan>;
        case "GreaterOrEqual".toUpperCase():
            return <FaGreaterThanEqual key={rnd} style={{ fontSize: "1rem", color: "var(--blueColor)" }} className="update-input-data-btn" alt={getOperacionSymbol(operacion)} title={getOperacionString(operacion)}></FaGreaterThanEqual>;
        case "LessOrEqual".toUpperCase():
            return <FaLessThanEqual key={rnd} style={{ fontSize: "1rem", color: "var(--blueColor)" }} className="update-input-data-btn" alt={getOperacionSymbol(operacion)} title={getOperacionString(operacion)}></FaLessThanEqual>;
        default:
            return getEmptyIcon();
    }
}

export function getMetodoEnvioIcon(metodo) {
    const rnd = String(Math.random());
    switch (String(metodo).toUpperCase()) {
        case "Sms".toUpperCase():
            return <BsPhoneVibrateFill key={rnd} style={{ fontSize: "1rem", color: "var(--blueColor)" }} className="update-input-data-btn" alt="SMS" title="SMS"></BsPhoneVibrateFill>;
        case "Mail".toUpperCase():
            return <BsFillEnvelopeAtFill key={rnd} style={{ fontSize: "1rem", color: "var(--blueColor)" }} className="update-input-data-btn" alt="Mail" title="Mail"></BsFillEnvelopeAtFill>;
        case "WhatsApp".toUpperCase():
            return <BsWhatsapp key={rnd} style={{ fontSize: "1rem", color: "var(--blueColor)" }} className="update-input-data-btn" alt="WhatsApp" title="WhatsApp"></BsWhatsapp>;
        default:
            return getEmptyIcon();
    }
}

export function getDashboardPicker() {
    return [
        "Ultimas 24 hs",
        "Ultimos 3 dias",
        "Ultima semana",
        "Ultimo Mes",
        "Ultimo Trimestre"
    ];
}

export function getDashboardPickedValue(metodo) {
    switch (String(metodo).toUpperCase()) {
        case "Ultimas 24 hs".toUpperCase():
            return "0";
        case "Ultimos 3 dias".toUpperCase():
            return "3";
        case "Ultima semana".toUpperCase():
            return "7";
        case "Ultimo Mes".toUpperCase():
            return "100";
        case "Ultimo Trimestre".toUpperCase():
            return "300";
        default:
            return "0";
    }
}

export function getRequiredIcon() {
    const rnd = String(Math.random());
    return <FaAsterisk key={rnd} style={{ fontSize: "0.5rem", color: "var(--bs-danger)" }} className="update-input-data-btn" alt="Requerido" title="Requerido"></FaAsterisk>;
}

export function getEmptyIcon() {
    const rnd = String(Math.random());
    return <BsDot key={rnd} style={{ fontSize: "0.5rem", color: "transparent", backgoundColor: "transparent" }} className="update-input-data-btn" alt="" title=""></BsDot>;
}

export async function getAlarmSelection() {
    const responseApi = await fetch(`Main/GetAlarmSelection`);
    if (responseApi.ok) {
        const responseObject = await responseApi.json();
        return responseObject;
    } return [];
}

export async function getAlarmSelectionFull() {
    const retrievedOperaciones = await getAlarmSelection();
    const operaciones = retrievedOperaciones.map(function (operacion) {
        return {
            valor: operacion,
            nombre: getAlarmSelectionString(operacion),
            simbolo: getAlarmSelectionSymbol(operacion)
        }
    });
    return operaciones;
}

export function getAlarmSelectionSymbol(operacion) {
    switch (String(operacion).toUpperCase()) {
        case "Top".toUpperCase():
            return "<";
        case "Middle".toUpperCase():
            return "<=>";
        case "Bottom".toUpperCase():
            return ">";
        default:
            return "?";
    }
}

export function getAlarmSelectionString(operacion) {
    switch (String(operacion).toUpperCase()) {
        case "Top".toUpperCase():
            return "Superior";
        case "Middle".toUpperCase():
            return "Intermedio";
        case "Bottom".toUpperCase():
            return "Inferior";
        default:
            return "?";
    }
}

export async function getAlarmEvaluation() {
    const responseApi = await fetch(`Main/GetAlarmEvaluation`);
    if (responseApi.ok) {
        const responseObject = await responseApi.json();
        return responseObject;
    } return [];
}

export async function getAlarmEvaluationFull() {
    const retrievedOperaciones = await getAlarmEvaluation();
    const operaciones = retrievedOperaciones.map(function (operacion) {
        return {
            valor: operacion,
            nombre: getAlarmEvaluationString(operacion),
            simbolo: getAlarmEvaluationSymbol(operacion)
        }
    });
    return operaciones;
}

export function getAlarmEvaluationSymbol(operacion) {
    switch (String(operacion).toUpperCase()) {
        case "Greatest".toUpperCase():
            return "Mayor";
        case "Lowest".toUpperCase():
            return "Menor";
        case "Average".toUpperCase():
            return "Promedio";
        case "Median".toUpperCase():
            return "Median";
        case "Sum".toUpperCase():
            return "Suma";
        case "Difference".toUpperCase():
            return "Diferencia";
        default:
            return "?";
    }
}

export function getAlarmEvaluationString(operacion) {
    switch (String(operacion).toUpperCase()) {
        case "Greatest".toUpperCase():
            return "Mayor";
        case "Lowest".toUpperCase():
            return "Menor";
        case "Average".toUpperCase():
            return "Promedio";
        case "Median".toUpperCase():
            return "Median";
        case "Sum".toUpperCase():
            return "Suma";
        case "Difference".toUpperCase():
            return "Diferencia";
        default:
            return "?";
    }
}

export function getAlarmEvaluationStringExtended(operacion) {
    switch (String(operacion).toUpperCase()) {
        case "Greatest".toUpperCase():
            return "el valor mayor del grupo";
        case "Lowest".toUpperCase():
            return "el valor menor del grupo";
        case "Average".toUpperCase():
            return "el promedio del grupo";
        case "Median".toUpperCase():
            return "el valor del medio del grupo";
        case "Sum".toUpperCase():
            return "la suma de valores del grupo";
        case "Difference".toUpperCase():
            return "la diferencia entre el mayor y el menor del grupo";
        default:
            return "?";
    }
}

export function getArrayFromString(inputString, separator = ',') {
    if (!separator || separator.toString().trim() === '') {
        separator = ',';
    }
    if (!inputString || inputString.toString().trim() === '') {
        return [];
    } else {
        return inputString.toString().split(separator).map(value => value.trim());
    }
}

export function getStringFromArray(array, separator = ',') {
    if (!separator || separator.toString().trim() === '') {
        separator = ',';
    }
    return array.map(capitalizeFirst).join(separator);
}

export function getPositiveAndNegativeFromArray(array) {
    let positive = [];
    let negative = [];

    if (array === null || array === undefined || array.length === 0)
    {
        return { positive, negative };
    }

    for (let str of array) {
        str = str.trim();
        if (str.startsWith("+")) {
            positive.push(str.substring(1));
        } else if (str.startsWith("-")) {
            negative.push(str.substring(1));
        }
    }
    return { positive, negative };
}

export function joinAndPrefixArrays(positive_values, negative_values) {
    positive_values = positive_values || [];
    negative_values = negative_values || [];

    const prefixed_positive_values = positive_values.map(item => "+" + item);
    const prefixed_negative_values = negative_values.map(item => "-" + item);

    const joint_values = prefixed_positive_values.concat(prefixed_negative_values);
    return joint_values;
}

export function getPositiveAndNegativeMessage(array, title) {
    let positive = [];
    let negative = [];

    for (let str of array) {
        str = str.trim();
        if (str.startsWith("+")) {
            positive.push(str.substring(1));
        } else if (str.startsWith("-")) {
            negative.push(str.substring(1));
        }
    }

    if (positive.length === 0 && negative.length === 0) {
        return getEmptyArrayMessage(title);
    }

    let message = (<></>);
    if (positive.length > 0) {
        message = (
            <>
                {message}
                <p>{title} Incluidos: <b>{getStringFromArray(positive)}</b></p>
            </>
        );
    }
    if (negative.length > 0) {
        message = (
            <>
                {message}
                <p>{title} Excluidos: <b>{getStringFromArray(negative)}</b></p>
            </>
        );
    }
    return message;
}

export function getEmptyArrayMessage(title) {
    let message = (
        <>
            <p>{title} Incluidos: <b>Todos</b></p>
        </>
    );
    return message;
}

export function getFullGroupedMessage(alarm) {
    let message = (
        <>
            <p><b>Nombre: </b>{capitalizeFirst(alarm.nombre)}</p>
        </>
    );
    if (alarm.detalle !== null && alarm.detalle !== undefined && alarm.detalle.length > 0) {
        message = (
            <>
                {message}
                <p><b>Detalle: </b><i>{alarm.detalle}</i></p>
            </>
        );
    }
    message = (
        <>
            {message}
            <p><b>Enviar a: </b>{alarm.usuarios.map(user => "'" + capitalizeFirst(user.nombre) + "'").join(', ')}</p>
            <p><b>Por: </b>{alarm.metodosDeEnvio.map(metodo => "'" + capitalizeFirst(metodo) + "'").join(', ')}</p>
            <p><b>Activa: </b>{alarm.activo ? "Si" : "No"}</p>
        </>
    );

    return message;
}

export function getFullSingleMessage(alarm) {
    let message = (
        <>
            <p><b>Nombre: </b>{capitalizeFirst(alarm.nombre)}</p>
        </>
    );
    if (alarm.detalle !== null && alarm.detalle !== undefined && alarm.detalle.length > 0) {
        message = (
            <>
                {message}
                <p><b>Detalle: </b><i>{alarm.detalle}</i></p>
            </>
        );
    }
    message = (
        <>
            {message}
            <p><b>Enviar a: </b>{alarm.usuarios.map(user => "'" + capitalizeFirst(user.nombre) + "'").join(', ')}</p>
            <p><b>Por: </b>{alarm.metodosDeEnvio.map(metodo => "'" + capitalizeFirst(metodo) + "'").join(', ')}</p>
            <p><b>Activa: </b>{alarm.activo ? "Si" : "No"}</p>
        </>
    );

    return message;
}

export function secondsToHours(seconds) {
    seconds = seconds || 0;

    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds % 3600) / 60);
    var remainingSeconds = Math.floor(seconds % 60);

    var formattedHours = ('0' + hours).slice(-2);
    var formattedMinutes = ('0' + minutes).slice(-2);
    var formattedSeconds = ('0' + remainingSeconds).slice(-2);

    return formattedHours + ':' + formattedMinutes + ':' + formattedSeconds;
}

export function convertHoursToSeconds(timeString) {
    var timeParts = timeString.split(':');
    
    var hours = parseInt(timeParts[0], 10);
    var minutes = parseInt(timeParts[1], 10);
    var seconds = parseInt(timeParts[2], 10);
    
    var totalSeconds = (hours * 3600) + (minutes * 60) + seconds;
    return Number(totalSeconds);
}

export function getFechaDesde(moveDays, gmt)
{
    if (moveDays === null || moveDays === undefined) {
        moveDays = -1;
    }
    if (gmt === null || gmt === undefined) {
        gmt = -3;
    }
    return new Date(Date.now() + (((24 * moveDays) + gmt)* 60 * 60 * 1000)).toISOString().slice(0, 10) + 'T00:00';
}

export function getFechaHasta(moveDays, gmt) {
    if (moveDays === null || moveDays === undefined) {
        moveDays = -1;
    }
    if (gmt === null || gmt === undefined) {
        gmt = -3;
    }
    return new Date(Date.now() + (((24 * moveDays) + gmt) * 60 * 60 * 1000)).toISOString().slice(0, 10) + 'T23:59';
}

export function getFechaMinima() {
    return '2024-05-01T00:00';
}

export function getFechaMaxima() {
    return new Date().toISOString().slice(0, 10) + 'T23:59';
}

export function getDateTimeDifferenceInMinutes(fechaDesdeValue, fechaHastaValue, diasPermitidos)
{
    if (diasPermitidos === null || diasPermitidos === undefined) {
        diasPermitidos = 15;
    }
    const diffInMinutes = Math.abs(new Date(fechaHastaValue) - new Date(fechaDesdeValue)) / (1000 * 60);
    const maxDifferenceInMinutes = diasPermitidos * 24 * 60;
    return diffInMinutes <= maxDifferenceInMinutes;
}

export function exportToExcel (tableId, reportName) {
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';

    const base64 = (s) => window.btoa(unescape(encodeURIComponent(s)));

    const format = function (template, context) {
        return template.replace(/{(\w+)}/g, (m, p) => context[p])
    };

    const html = document.getElementById(tableId).innerHTML;
    const ctx = {
        worksheet: 'Worksheet',
        table: html,
    };

    const currentDate = new Date();
    const formatedDate = formatDateToCustomString(currentDate, 'yyyyMMdd_HHmmss');

    const link = document.createElement("a");
    link.download = `${reportName}_${formatedDate}.xls`;
    link.href = uri + base64(format(template, ctx));
    link.click();
}

export function exportToPdf (tableId, reportName) {
    const element = document.querySelector(`#${tableId}`);
    const currentDate = new Date();
    const formatedDate = formatDateToCustomString(currentDate, 'yyyyMMdd_HHmmss');
    const opt = {
        margin: 1,
        filename: `${reportName}_${formatedDate}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'cm', format: 'A4', orientation: 'portrait' }
    };
    html2pdf.default(element, opt);
}
