import React, { Component } from 'react';
import { SimpleAlertComponent, SimpleSuccessComponent } from './Alerts';
import { validateField } from '../utils';

export class Shared extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            alertMessage: '',
            successMessage: ''
        };
    }

    getMessageArea = () => {
        return (
            <div>
                <SimpleAlertComponent message={this.state.alertMessage} onClose={this.handleAlertClose} />
                <SimpleSuccessComponent message={this.state.successMessage} onClose={this.handleSuccesClose} />
            </div>
        );
    };

    setAlertMessage = (message) => {
        this.setState({
            alertMessage: message
        });
    };

    setSuccessMessage = (message) => {
        this.setState({
            successMessage: message
        });
    };

    handleAlertClose = () => {
        this.setAlertMessage('');
    };

    handleSuccesClose = () => {
        this.setSuccessMessage('');
    };

    getRender = () => {
        return (
            <div className="spinner-border text-azul" role="status">
                &nbsp;
            </div>
        );
    }
    
    getUnauthorizedPage = () => {
        window.location.replace("/");
        return (
            <>
                <div>
                </div>
            </>
        );
    };

    checkField = (inputValue, validationObj) => {
        const validationResult = validateField(inputValue, validationObj);
        if (validationResult === '') {
            return true;
        }
        this.setAlertMessage(validationResult);
        return false;
    };
}
