import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { NavFooter } from './NavFooter';

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
        <div className="main-data">
            <NavMenu setAuthenticated={this.props.setAuthenticated} />
            <div className="container-data">
                <Container>
                    {this.props.children}
                </Container>
            </div>
            <NavFooter />
      </div>
    );
  }
}
