import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import {  SelectRoutes } from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';

export const productInfo = {
    appName: "iAlarmas"
};

export default class App extends Component {
    static displayName = App.name;
    render() {
        return (
            <Layout setAuthenticated={this.props.setAuthenticated}>
                <Routes>
                    {SelectRoutes().map((route, index) => {
                        const { element, ...rest } = route;
                        return <Route key={index} {...rest} element={element} />;
                    })}
                </Routes>
            </Layout>
        );
    }
}
