import React from 'react';
import { Shared } from '../components/Shared';
import { capitalizeFirst, getCurrentSession, empresaObj, usuarioObj } from '../utils';
import './NavFooter.css';

export class NavFooter extends Shared {
    constructor(props) {
        super(props);

        this.state = {
            loggedUser: null
        };
    }

    componentDidMount() {
        this.populateFooterData();
    }

    populateFooterData = async () => {
        this.setState({
            loggedUser: getCurrentSession()
        });
    }

    getLandingPage = () => {
        return (
            <div className="divFooter">
                <label style={{ float: "left" }}><strong>{empresaObj.label}</strong>: {capitalizeFirst(this.state.loggedUser.empresa.nombre)}</label><label style={{ float: "right" }}><strong>{usuarioObj.label}</strong>: {capitalizeFirst(this.state.loggedUser.nombre)}</label>
            </div>
        );
    };

    getEmptyPage = () => {
        return (
            <div className="divFooter">
               &nbsp;
            </div>
        );
    };

    render() {
        return this.state.loggedUser === null ? this.getEmptyPage() : this.getLandingPage();
    }
}